export const environment = {
    policyIssuer: "Safratti",
    GTS: {
      gtsUrl: "https://api-uat.globetravelsolution.com/api/",
      gtsCrmUrl: "https://crm-uat.globetravelsolution.com/api/",
      policyIssuer: "GTS",
    },
    Safratti: {
      gtsUrl: "https://qaapi.saiyarti.com/api/",
      gtsCrmUrl: "https://qaapi.saiyarti.com/api/",
      policyIssuer: "Safratti",
    },
    production: false,
    apiUrl: "https://qaapi.saiyarti.com/api/",
    gtsCrmUrl: "https://crm-uat.globetravelsolution.com/api/",
    storageAccount: "saiyartidev",
    cdnUrl: "https://saiyartidev.blob.core.windows.net",
    apiKey: "27zBLM6ygC6ynAdY",
    apiPassword: "xz6pcj4VxkRMnbxXB2BheReTmT3m4A7wcSA9qbMk",
    dynamicLink: "https://saiyartiqa.page.link/",
    redirectWebURL:
      "https://comprehensive-frontend-dev.azurewebsites.net/customer-login",
    safrattiURL: "https://qa.safratti.com",
    gtsUrl: "https://api-uat.globetravelsolution.com/api/",
    branchKey: "key_live_jhGAggrLj96EcjCzKsKRLkdjvDcIEwyZ",
    firebase: {
      apiKey: "AIzaSyBsgnPpI5GWdf-f2JeTxIeeKKRVpWoIUQk",
      authDomain: "saiyarti-qa.firebaseapp.com",
      databaseURL: "https://saiyarti-qa.firebaseio.com",
      projectId: "saiyarti-qa",
      storageBucket: "saiyarti-qa.appspot.com",
      messagingSenderId: "845787692106",
      appId: "1:845787692106:web:01a35f2535671c86ef3ab3",
      measurementId: "G-DLYX14SKXX",
    },
    authenticate: {
      Username: "agent2@lskw.com",
      Password: "p@ssw0rd",
      PasswordGrantClientId: "gts-admin",
      PasswordGrantClientSecret: "gts-client-admin-secret",
      PasswordGrantScope: "ms.gts",
    },
    authenticateZain: {
      Username: "test@zain.com",
      Password: "Password",
      PasswordGrantClientId: "gts-admin",
      PasswordGrantClientSecret: "gts-client-admin-secret",
      PasswordGrantScope: "ms.gts",
    }
  };