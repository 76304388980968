// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: true,
  // policyIssuer: 'Safratti',
  // apiUrl: 'https://api.saiyarti.com/api/',
  // gtsCrmUrl: 'https://crm-adapter.globetravelsolution.com/api/',
  // storageAccount: 'saiyartiproduction',
  // cdnUrl: 'https://saiyartiproduction.blob.core.windows.net',
  // apiKey: 'bY4Nya4TQwkSRQcL',
  // apiPassword: 'KC7d9g48vfvpsxuxrrjxT2RTV6StUcamJYevgtCC',
  // dynamicLink: 'https://saiyarti.page.link/',
  // redirectWebURL: 'https://comprehensive.saiyarti.com/customer-login',
  // safrattiURL:'https://www.safratti.com',
  // firebase: {
  //   apiKey: 'AIzaSyA0CQAJMeKenEeQLnO3SLzqtMu_ZzLN6z8',
  //   authDomain: 'neural-sunup-231810.f  irebaseapp.com',
  //   databaseURL: 'https://neural-sunup-231810.firebaseio.com',
  //   projectId: 'neural-sunup-231810',
  //   storageBucket: 'neural-sunup-231810.appspot.com',
  //   messagingSenderId: '6675864888',
  //   appId: '1:6675864888:web:d1b7e67be85d8d317675cd',
  //   measurementId: 'G-CN8N5CLX5E'
  // }
  policyIssuer: 'Safratti',
  production: true,
  apiUrl: 'https://qaapi.saiyarti.com/api/',
  gtsCrmUrl: 'https://qaapi.saiyarti.com/api/',
  storageAccount: 'saiyartidev',
  cdnUrl: 'https://saiyartidev.blob.core.windows.net',
  apiKey: '27zBLM6ygC6ynAdY',
  apiPassword: 'xz6pcj4VxkRMnbxXB2BheReTmT3m4A7wcSA9qbMk',
  dynamicLink: 'https://saiyartiqa.page.link/',
  redirectWebURL: 'https://comprehensive-frontend-dev.azurewebsites.net/customer-login',
  safrattiURL:'https://qa.safratti.com',
  gtsUrl: 'https://api-uat.globetravelsolution.com/api/',
  branchKey:'key_live_jhGAggrLj96EcjCzKsKRLkdjvDcIEwyZ',
  firebase: {
    apiKey: 'AIzaSyBsgnPpI5GWdf-f2JeTxIeeKKRVpWoIUQk',
    authDomain: 'saiyarti-qa.firebaseapp.com',
    databaseURL: 'https://saiyarti-qa.firebaseio.com',
    projectId: 'saiyarti-qa',
    storageBucket: 'saiyarti-qa.appspot.com',
    messagingSenderId: '845787692106',
    appId: '1:845787692106:web:01a35f2535671c86ef3ab3',
    measurementId: 'G-DLYX14SKXX'
  },
  authenticate: {
    "Username": "agent2@lskw.com",
    "Password": "p@ssw0rd",
    "PasswordGrantClientId": "gts-admin",
    "PasswordGrantClientSecret": "gts-client-admin-secret",
    "PasswordGrantScope": "ms.gts"
  }
};

// export const environment = {
//   production: true,
//   apiUrl: 'https://qaapi.saiyarti.com/api/',
//   storageAccount: 'saiyartidev',
//   cdnUrl: 'https://saiyartidev.blob.core.windows.net',
//   apiKey: '27zBLM6ygC6ynAdY',
//   apiPassword: 'xz6pcj4VxkRMnbxXB2BheReTmT3m4A7wcSA9qbMk',
//   dynamicLink: 'https://saiyarti.page.link/',
//   redirectWebURL: 'https://comprehensive-frontend-dev.azurewebsites.net/customer-login',
//   firebase: {
//     apiKey: 'AIzaSyA0CQAJMeKenEeQLnO3SLzqtMu_ZzLN6z8',
//     authDomain: 'neural-sunup-231810.f  irebaseapp.com',
//     databaseURL: 'https://neural-sunup-231810.firebaseio.com',
//     projectId: 'neural-sunup-231810',
//     storageBucket: 'neural-sunup-231810.appspot.com',
//     messagingSenderId: '6675864888',
//     appId: '1:6675864888:web:d1b7e67be85d8d317675cd',
//     measurementId: 'G-CN8N5CLX5E'
//   }
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
