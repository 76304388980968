import { OnInit, AfterViewInit, ViewChild, ElementRef, Directive } from '@angular/core';
import { ICountService } from 'src/services/icount.service';
import { fromEvent, merge, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';

@Directive()
export abstract class TableComponent<T> implements AfterViewInit, OnInit, DataSource<T> {

    public count: number;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('input', { static: true }) input: ElementRef;


    protected dataSubject = new BehaviorSubject<T[]>([]);
    protected loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    selection = new SelectionModel<any>(true, []);    

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.dataSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.dataSubject.complete();
    }

    constructor(private countService: ICountService) { }


    ngOnInit(): void {
        this.getCount().subscribe(res => {
            this.count = res.count;
            this.countService.getData(this.getData())
                .subscribe(data => {
                    this.loadingSubject.next(false);
                    this.dataSubject.next(data);
                });
        });
    }
    ngAfterViewInit(): void {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.getCount().subscribe(res => {
                        this.count = res.count;
                        this.loadRequests(this.getData());
                    });
                })
            )
            .subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadRequests(this.getData()))
            )
            .subscribe();
    }

    getCount() {
        return this.countService.getCount({ filter: this.input.nativeElement.value });
    }

    protected getData(): any {
        let pageSize = this.paginator.pageSize ? this.paginator.pageSize : 10;
        return {
            skip: this.paginator.pageIndex * pageSize,
            take: pageSize,
            filter: this.input.nativeElement.value,
            orderBy: this.sort.active,
            orderByDescending: this.sort.direction === 'desc'
        };
    }

    protected loadRequests(data: any) {
        this.loadingSubject.next(true);

        this.countService.getData(data).subscribe(res => {
            this.loadingSubject.next(false);
            this.dataSubject.next(res);
            this.selection.clear()
        }, err => {
            this.loadingSubject.next(false);
        });
    }

    protected isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =this.dataSubject.getValue().length;
        return numSelected === numRows;
      }

    
    protected  masterToggle() {
        const data = this.dataSubject.getValue();
        if (this.isAllSelected()) {
          this.selection.clear();
        } else {
          this.selection.clear();
          data.forEach((row) => this.selection.select(row));
        }
      }
    
    protected  toggleSelection(row: any) {
        this.selection.toggle(row);
      }
    
    protected  checkboxLabel(row?: any): string {
        if (!row) {
          return `${this.isAllSelected() ? "deselect" : "select"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row`;
      }
}
