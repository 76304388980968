import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewCustomer } from 'src/models/newcustomer';
import { Location } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { RenewComprehensiveCustomerService } from 'src/services/renew-comprehensive-customer.service';


@Component({
  selector: 'app-renew-comprehensive-customer-detail',
  templateUrl: './renew-comprehensive-customer-detail.component.html',
  styleUrls: ['./renew-comprehensive-customer-detail.component.css']
})
export class RenewComprehensiveCustomerDetailComponent {

  customer = new NewCustomer();
  currentYear: number = (new Date()).getFullYear();
  formDisabled = true;

  constructor(private renewComprehensiveCustomerService: RenewComprehensiveCustomerService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
   ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    this.renewComprehensiveCustomerService.get(+id).subscribe(res => {
      this.customer = res;
    });
  }

  back() {
    this.location.back();
  }
  updateCustomer(form) {
    const id = this.route.snapshot.paramMap.get('id');

    this.renewComprehensiveCustomerService.update(+id, this.customer).subscribe(res => {
      this.back();
    });
  }
}
