import { Component, OnInit } from "@angular/core";
import { RequestService } from "src/services/request.service";
import { TableComponent } from "../table.component";
import { SelectionModel } from "@angular/cdk/collections";
import { Router } from "@angular/router";
import { SafrattiRequestService } from "src/services/safratti-request.service";
import { TravelerDetail } from "src/models/traveler-detail";

export class Customer {
  id: number;
  customerName: string;
  userName: string;
  requestType: string;
  vehicleType: string;
  date: string;
  amount: string;
  status: string;
}

@Component({
  selector: "app-consumer-safratti-request",
  templateUrl: "./consumer-safratti-request.component.html",
  styleUrls: ["./consumer-safratti-request.component.css"],
})
export class ConsumerSafrattiRequestComponent extends TableComponent<Request> {
  pageTitle = "Request";
  requestsArray = [];
  error: any;
  displayedColumns: string[] = [
    "id",
    "customerName",
    "email",
    "phoneNumber",
    "policyType",
    "policyNumber",
    "requestType",
    "date",
    "status",
    "basePremium",
    "policyFee",
    "totalFee",
    "totalPremium",
    "convenienceFee",
    "netAmount",
    "discountAmount",
    "promotionName",
    "paymentReference",
    "refund",
    "collection",
    "source",
    "issuer",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private safrattiService: SafrattiRequestService,
    private router: Router
  ) {
    super(safrattiService);
  }

  openPurchaseDetail(userdata: TravelerDetail): void {
    if (userdata.status == "POLICY_ISSUED") {
      this.router.navigate(
        ["consumer-safratti-request/policy-status/" + userdata.id],
        { state: { policyIssuer: userdata.issuer } }
      );
    }
    if (userdata.status == "POLICY_MODIFIED") {
      this.router.navigate([
        "consumer-safratti-request/policy-detail/" + userdata.id,
      ]);
    } else {
    }
  }

  formatIssueDate(val: any) {
    const [datePart, timePart] = val.split("T");

    const utcDate = new Date(`${datePart}T${timePart}Z`);

    const kuwaitOffset = 3 * 60 * 60 * 1000; // Kuwait is UTC+3 hours
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitOffset);

    const hours = String(kuwaitDate.getUTCHours()).padStart(2, "0");
    const minutes = String(kuwaitDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(kuwaitDate.getUTCSeconds()).padStart(2, "0");
    const kuwaitTimeString = `${hours}:${minutes}:${seconds}`;

    const kuwaitDateFormatted = `${String(kuwaitDate.getUTCDate()).padStart(2, "0")}/${String(kuwaitDate.getUTCMonth() + 1).padStart(2, "0")}/${kuwaitDate.getUTCFullYear()}`;
    return `${kuwaitDateFormatted} ${kuwaitTimeString}`;
  }
}
