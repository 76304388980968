import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrderService } from "src/services/order.service";
import { TableComponent } from "../table.component";
import { SafrattiOrderService } from "src/services/safratti-order.service";

@Component({
  selector: "app-safratti-orders",
  templateUrl: "./safratti-orders.component.html",
  styleUrls: ["./safratti-orders.component.css"],
})
export class SafrattiOrdersComponent extends TableComponent<Request> {
  pageTitle = "Orders";
  displayedColumns: string[] = [
    "id",
    "orderDate",
    "customerName",
    "userName",
    "referralName",
    "orderType",
    "email",
    "phoneNumber",
    "insuranceCom",
    "policyNumber",
    "basePremium",
    "totalFee",
    "policyFee",
    "totalPremium",
    "convenienceFee",
    "netAmount",
    "discountAmount",
    "promotionName",
    "paymentReference",
    "refund",
    "collection",
    "notes",
    "status",
    "source",
    "issuer",
  ];

  selection = new SelectionModel<any>(true, []);

  constructor(orderService: SafrattiOrderService, private router: Router) {
    super(orderService);
  }

  openOrder(data: any) {
    this.router.navigate(["/safratti-orders/" + data.id], {
      state: { policyIssuer: data.issuer },
    });
  }

  formatOrderDate(val) {
    const [datePart, timePart] = val.split("T");

    const utcDate = new Date(`${datePart}T${timePart}Z`);

    const kuwaitOffset = 3 * 60 * 60 * 1000; // Kuwait is UTC+3 hours
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitOffset);

    const hours = String(kuwaitDate.getUTCHours()).padStart(2, "0");
    const minutes = String(kuwaitDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(kuwaitDate.getUTCSeconds()).padStart(2, "0");
    const kuwaitTimeString = `${hours}:${minutes}:${seconds}`;

    const kuwaitDateFormatted = `${String(kuwaitDate.getUTCDate()).padStart(2, "0")}/${String(kuwaitDate.getUTCMonth() + 1).padStart(2, "0")}/${kuwaitDate.getUTCFullYear()}`;
    return `${kuwaitDateFormatted} ${kuwaitTimeString}`;
  }
}
