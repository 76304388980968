import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AnalyticsModule } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../helpers/canActivateAuthGuard';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatStepperModule } from '@angular/material/stepper';
import { BlobModule } from 'angular-azure-blob-service';
import { LightboxModule } from 'ngx-lightbox';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MAT_DATE_FORMATS } from '@angular/material/core';


// Services
import { DashboardService } from 'src/services/dashboard.service';
import { OrderService } from 'src/services/order.service';
import { TokenService } from 'src/services/token.service';
import { StorageService } from 'src/services/storage.service';
import { RequestService } from 'src/services/request.service';
import { RCBookService } from 'src/services/rcbook.service';
import { CustomerService } from 'src/services/customer.service';
import { CompanyService } from 'src/services/company.service';
import { AgentService } from 'src/services/agent.service';
import { AccountService } from 'src/services/account.service';
import { UsersService } from 'src/services/users.service';
import { TplService } from 'src/services/tpl.service';
import { TransportService } from 'src/services/transport.service';
import { TplPurchaseService } from 'src/services/tpl-purchase.service';
import { PointService } from 'src/services/point.service';
import { DialogService } from 'src/services/dialog.service';
import { ReferralService } from 'src/services/referral.service';

// ** Helper **
import { ConfirmEqualValidatorDirective } from 'src/helpers/confirm-equal-validator.directive';
import { NumericDirective } from 'src/helpers/NumericDirective';

// ** Models
import { TransportDocXyModelComponent } from './model-dialog/transport-doc-xy-model/transport-doc-xy-model.component';
import { DocEditXyModelComponent } from './model-dialog/doc-edit-xy-model/doc-edit-xy-model.component';
import { DocViewComponent } from './model-dialog/doc-view/doc-view.component';

// ** Directives */

import { BackButtonDirective } from 'src/directive/back-button-directive';
import { DatePipe } from 'src/directive/date-pipe';

// ** Component **

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyPolicyComponent } from './secure/privacy-policy/privacy-policy.component';
import { LoginComponent } from './login/login.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { HeaderComponent } from './header/header.component';
import { LogoutComponent } from './login/logout.component';
import { Helpers } from 'src/helpers/helpers';
import { HttpClient } from '@angular/common/http';
import { DashboardComponent } from './secure/dashboard/dashboard.component';
import { PurchaseDetailComponent } from './secure/purchase-detail/purchase-detail.component';
import { ConsumerRequestComponent } from './secure/consumer-request/consumer-request.component';
import { PolicyInfoComponent } from './secure/policy-info/policy-info.component';
import { PolicyStatusComponent } from './secure/policy-status/policy-status.component';
import { TplRequestStepperComponent } from './tpl-request-stepper/tpl-request-stepper.component';
import { CustomerComponent } from './secure/customer/customer.component';
import { CustomerDetailComponent } from './secure/customer-detail/customer-detail.component';
import { CompaniesComponent } from './secure/companies/companies.component';
import { B2BCompaniesComponent } from './b2bcompanies/b2bcompanies.component';
import { BrokersComponent } from './secure/brokers/brokers.component';
import { CompanyDetailComponent } from './secure/company-detail/company-detail.component';
import { BrokerDetailComponent } from './secure/broker-detail/broker-detail.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { FooterComponent } from './footer/footer.component';
import { PolicyVerifyPrintComponent } from './secure/policy-verify-print/policy-verify-print.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { HomeComponent } from './home/home.component';
import { SetupComponent } from './secure/setup/setup.component';
import { AgentListComponent } from './secure/agent-list/agent-list.component';
import { AgentAddComponent } from './secure/agent-add/agent-add.component';
import { ChangePasswordComponent } from './secure/change-password/change-password.component';
import { UsersComponent } from './secure/users/users.component';
import { UserDetailComponent } from './secure/users/user-detail/user-detail.component';
import { FeeSetupComponent } from './secure/setup/fee-setup/fee-setup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecureLayoutComponent } from './secure/secure-layout/secure-layout.component';
import { TransportDepartmentDocComponent } from './secure/setup/transport-department-doc/transport-department-doc.component';
import { OrdersComponent } from './secure/orders/orders.component';
import { PointTransactionsComponent } from './secure/point-transactions/point-transactions.component';
import { OrderDetailComponent } from './secure/orders/order-detail/order-detail.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { PrintLabelComponent } from './model-dialog/print-label/print-label.component';
import { RedeemComponent } from './secure/users/redeem/redeem.component';
import { ReportsComponent } from './secure/reports/reports.component';
import { InsurerReportComponent } from './secure/reports/insurer-report/insurer-report.component';
import { OrderSummaryReportComponent } from './secure/reports/order-summary-report/order-summary-report.component';
import { IssuanceSummaryReportComponent } from './secure/reports/issuance-summary-report/issuance-summary-report.component';
import { IssuanceReportComponent } from './secure/reports/issuance-report/issuance-report.component';
import { UnissuedPoliciesReportComponent } from './secure/reports/unissued-policies-report/unissued-policies-report.component';
import { RequestsReportComponent } from './secure/reports/requests-report/requests-report.component';
import { ReferralComponent } from './secure/setup/referral/referral.component';
import { ReferralAddComponent } from './secure/setup/referral-add/referral-add.component';
import { ReferralReportComponent } from './secure/reports/referral-report/referral-report.component';
import { ReferralUserReportComponent } from './secure/reports/referral-user-report/referral-user-report.component';
import { InsurerReportService } from 'src/services/reports/insurer.service';
import { OrderSummaryReportService } from 'src/services/reports/order-summary.service';
import { IssuanceReportService } from 'src/services/reports/issuance.service';
import { IssuanceSummaryReportService } from 'src/services/reports/issuance-summary.service';
import { UnIssuedReportService } from 'src/services/reports/unissued.service';
import { RequestsReportService } from 'src/services/reports/requests.service';
import { ReferralReportService } from 'src/services/reports/referral.service';
import { ReferralUserReportService } from 'src/services/reports/referral-user.service';
import { RcbookDetailComponent } from './secure/rcbook-detail/rcbook-detail.component';
import { PasswordConfirmDialogComponent } from './password-confirm-dialog/password-confirm-dialog.component';
import { EmailVerifiedComponent } from './email-verified/email-verified.component';
import { VehicleCatalogComponent } from './secure/setup/vehicle-catalog/vehicle-catalog.component';
import { NewCustomerImportComponent } from './secure/setup/new-customer-import/new-customer-import.component';
import { NewCustomerDetailComponent } from './secure/setup/new-customer-detail/new-customer-detail.component';
import { UsernamePasswordDialogComponent } from './username-password-dialog/username-password-dialog.component';
import { CollectiblesComponent } from './collectibles/collectibles.component';
import { PaymentService } from 'src/services/reports/payment.service';
import { PoliciesComponent } from './policies/policies.component';
import { PoliciesService } from 'src/services/policies.service';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { B2bPolicyPrintComponent } from './b2b-policy-print/b2b-policy-print.component';
import { CompanyLoginComponent } from './company-login/company-login.component';
import { B2BCompanyDetailComponent } from './b2bcompany-detail/b2bcompany-detail.component';
import { RedirectComponent } from './redirect/redirect.component';
import { QrcodeComponent } from '../directive/qrcode/qrcode.component';
import { ConfirmReasonDialogComponent } from './confirm-reason-dialog/confirm-reason-dialog.component';
import { CallbackPipe } from 'src/services/filter';
import { B2BBulkUploadDialogComponent } from './b2b-bulk-upload-dialog/b2b-bulk-upload-dialog.component';
import { SpersonScoordinatorComponent } from './sperson-scoordinator/sperson-scoordinator.component';
import { SpersonScoordinatorDetailComponent } from './sperson-scoordinator-detail/sperson-scoordinator-detail.component';
import { StoreComponent } from './store/store.component';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { MakePaymentDialogComponent } from './make-payment-dialog/make-payment-dialog.component';
import { environment } from 'src/environments/environment';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserService } from 'src/services/user.service';
import { AccessGuard } from 'src/helpers/access.guard';
import { RenewCustomersComponent } from './renew-customers/renew-customers.component';
import { RenewCustomerDetailComponent } from './renew-customer-detail/renew-customer-detail.component';
import { RenewCustomerService } from 'src/services/renewcustomer.service';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SelectLanguageComponent } from './model-dialog/select-language/select-language.component';
import { MasterRatecardComponent } from './secure/master-ratecard/master-ratecard.component';
import { AddLimitDialogComponent } from './add-limit-dialog/add-limit-dialog.component';
import { AddRatecardComponent } from './secure/add-ratecard/add-ratecard.component';
import { AddYearDialogComponent } from './add-year-dialog/add-year-dialog.component';
import { ComprehensiveConsumerRequestComponent } from './secure/comprehensive-consumer-request/comprehensive-consumer-request.component';
import { CrDetailComponent } from './secure/cr-detail/cr-detail.component';
import { ForwardQuoteDialogComponent } from './forward-quote-dialog/forward-quote-dialog.component';
import { ComprehensiveRequestStepperComponent } from './comprehensive-request-stepper/comprehensive-request-stepper.component';
import { ComprehensivePolicyInfoComponent } from './secure/comprehensive-policy-info/comprehensive-policy-info.component';
import { ComprehensivePolicyStatusComponent } from './secure/comprehensive-policy-status/comprehensive-policy-status.component';
import { ComprehensiveUploadDocComponent } from './secure/comprehensive-upload-doc/comprehensive-upload-doc.component';
import { QuoteHistoryDetailsComponent } from './secure/quote-history-details/quote-history-details.component';
import { ComprehensiveOrderComponent } from './secure/comprehensive-order/comprehensive-order.component';
import { ComprehensiveOrderDetailComponent } from './secure/comprehensive-order-detail/comprehensive-order-detail.component';
import { VehicleDetailComponent } from './secure/vehicle-detail/vehicle-detail.component';
import { ComprehensiveIssuanceSummaryReportsComponent } from './secure/reports/comprehensive-issuance-summary-reports/comprehensive-issuance-summary-reports.component';
import { ComprehensiveOrderSummaryReportsComponent } from './secure/reports/comprehensive-order-summary-reports/comprehensive-order-summary-reports.component';
import { SafrattiRedirectComponent } from './safratti-redirect/safratti-redirect.component';
import { ConsumerSafrattiRequestComponent } from './consumer-safratti-request/consumer-safratti-request.component';
import { SafrattiOrdersComponent } from './safratti-orders/safratti-orders.component';
import { SafrattiOrderDetailComponent } from './safratti-order-detail/safratti-order-detail.component';
import { SafrattiPolicyDetailComponent } from './safratti-policy-detail/safratti-policy-detail.component';
import { SafrattiPolicyHistoryComponent } from './safratti-policy-history/safratti-policy-history.component';
import { SafrattiPolicyStatusComponent } from './safratti-policy-status/safratti-policy-status.component';
import { SafrattiReportComponent } from './safratti-report/safratti-report.component';
import { SafrattiIssuanceReportComponent } from './safratti-issuance-report/safratti-issuance-report.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SafratiiFeeStructureComponent } from './secure/setup/safratii-fee-structure/safratii-fee-structure.component';
import { SafrattiFeeStructureAddComponent } from './secure/setup/safratti-fee-structure-add/safratti-fee-structure-add.component';

import { SafarattiTravelPlanListComponent } from './safaratti-travel-plan-list/safaratti-travel-plan-list.component';
import { SafarattiTravelPlanAddComponent } from './safaratti-travel-plan-add/safaratti-travel-plan-add.component';
import { SafrattiTravelPlanBenefitsAddComponent } from './safratti-travel-plan-benefits-add/safratti-travel-plan-benefits-add.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SafrattiPolicyTravelerDetailsComponent } from './safratti-policy-traveler-details/safratti-policy-traveler-details.component';
import { SafrattiPolicyComponent } from './safratti-policy/safratti-policy.component';
import { SafrattiPolicyPaymentDetailComponent } from './safratti-policy-payment-detail/safratti-policy-payment-detail.component';
import { SafrattiPolicyContactDetailComponent } from './safratti-policy-contact-detail/safratti-policy-contact-detail.component';
import { SafrattiFeeStructureAgeComponent } from './secure/setup/safratti-fee-structure-age/safratti-fee-structure-age.component';
import { SafrattiPromoCodeComponent } from './secure/setup/safratti-promo-code/safratti-promo-code.component';
import { SafrattiPromoCodeAddComponent } from './secure/setup/safratti-promo-code-add/safratti-promo-code-add.component';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import { GestureConfig } from "../gesture-config";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY', 
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


const AntdModules = [
  NzTableModule,
]

const appRoutes: Routes = [
  // { path: '', component: HomeComponent },
  { path: '', component: LoginComponent, canActivate: [AccessGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AccessGuard] },
  { path: 'company-login', component: CompanyLoginComponent },
  { path: 'user-login', component: UserLoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'redirect/:id', component: RedirectComponent },
  { path: 'safratti-redirect',component:SafrattiRedirectComponent},
  /**
   * Secure Router
   */
  {
    path: '',
    component: SecureLayoutComponent,
    children: [
      // { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'consumer-request', component: ConsumerRequestComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request', component: ComprehensiveConsumerRequestComponent, canActivate: [AuthGuard] },
      { path: 'consumer-safratti-request', component: ConsumerSafrattiRequestComponent, canActivate: [AuthGuard] },
      { path: 'consumer-safratti-request/policy-detail/:id', component:SafrattiPolicyDetailComponent,canActivate: [AuthGuard]},
      { path: 'consumer-safratti-request/policy-history/:id', component:SafrattiPolicyHistoryComponent,canActivate: [AuthGuard]},
      { path: 'consumer-safratti-request/policy-status/:id', component:SafrattiPolicyStatusComponent, canActivate: [AuthGuard]},
      { path: 'consumer-request/purchase-detail/:id', component: PurchaseDetailComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request/cr-detail/:id', component: CrDetailComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request/qh-detail/:id', component: QuoteHistoryDetailsComponent, canActivate: [AuthGuard] },
      { path: 'consumer-request/policy-info/:id', component: PolicyInfoComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request/policy-info/:id', component: ComprehensivePolicyInfoComponent, canActivate: [AuthGuard] },
      { path: 'consumer-request/policy-status/:id', component: PolicyStatusComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request/policy-status/:id', component: ComprehensivePolicyStatusComponent, canActivate: [AuthGuard] },
      { path: 'consumer-request/policy-verify-print/:id', component: PolicyVerifyPrintComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-consumer-request/upload-document/:id', component: ComprehensiveUploadDocComponent, canActivate: [AuthGuard] },
      { path: 'customers', component: CustomerComponent, canActivate: [AuthGuard] },
      { path: 'customers/:id', component: CustomerDetailComponent, canActivate: [AuthGuard] },
      { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard] },
      { path: 'brokers', component: BrokersComponent, canActivate: [AuthGuard] },
      { path: 'companies/:id', component: CompanyDetailComponent, canActivate: [AuthGuard] },
      { path: 'brokers/:id', component: BrokerDetailComponent, canActivate: [AuthGuard] },
      { path: 'setup', component: SetupComponent, canActivate: [AuthGuard] },
      { path: 'setup/agent-list', component: AgentListComponent, canActivate: [AuthGuard] },
      { path: 'setup/fee-setup', component: FeeSetupComponent, canActivate: [AuthGuard] },
      { path: 'setup/safratti-fee-setup', component: SafratiiFeeStructureComponent, canActivate: [AuthGuard] },
      { path: 'setup/safratti-fee-add', component: SafrattiFeeStructureAddComponent, canActivate: [AuthGuard] },
      { path: 'setup/safratti-fee-add/:id', component: SafrattiFeeStructureAddComponent, canActivate: [AuthGuard] },
      { path: 'setup/transport-department-doc', component: TransportDepartmentDocComponent, canActivate: [AuthGuard] },
      { path: 'setup/referral', component: ReferralComponent, canActivate: [AuthGuard] },
      { path: 'setup/referral/add', component: ReferralAddComponent, canActivate: [AuthGuard] },
      { path: 'agents', component: AgentListComponent, canActivate: [AuthGuard] },
      { path: 'setup/agents/:id', component: AgentAddComponent, canActivate: [AuthGuard] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
      { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'users/:id/redeem', component: RedeemComponent, canActivate: [AuthGuard] },
      { path: 'users/:id/report', component: UserDashboardComponent, canActivate: [AuthGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
      { path: 'orders/:id', component: OrderDetailComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-orders', component: ComprehensiveOrderComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-orders/:id', component: ComprehensiveOrderDetailComponent, canActivate: [AuthGuard] },
      { path: 'safratti-orders' , component:SafrattiOrdersComponent, canActivate:[AuthGuard]},
      { path: 'safratti-orders/:id' , component:SafrattiOrderDetailComponent, canActivate:[AuthGuard]},
      { path: 'point-transactions', component: PointTransactionsComponent, canActivate: [AuthGuard] },
      { path: 'receipt', component: ReceiptComponent, canActivate: [AuthGuard] },
      
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/insurer_report', component: InsurerReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/order-summary-report', component: OrderSummaryReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/issuance-summary-report', component: IssuanceSummaryReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/comprehensive-order-summary-report', component: ComprehensiveOrderSummaryReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/comprehensive-issuance-summary-report', component: ComprehensiveIssuanceSummaryReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/issuance-report', component: IssuanceReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/unissued-policies-report', component: UnissuedPoliciesReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/requests-report', component: RequestsReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/referral-report', component: ReferralReportComponent, canActivate: [AuthGuard] },
      { path: 'reports/referral-report/:id/:name', component: ReferralUserReportComponent, canActivate: [AuthGuard] },

      { path: 'safratti-reports', component: SafrattiReportComponent, canActivate: [AuthGuard] },
      { path: 'safratti-reports/safratti-issuance-report', component: SafrattiIssuanceReportComponent, canActivate: [AuthGuard] },
      { path: 'setup/safratti-travel-plan-list', component: SafarattiTravelPlanListComponent, canActivate:[AuthGuard]},
      { path: 'setup/safratti-travel-plan-add', component: SafarattiTravelPlanAddComponent, canActivate:[AuthGuard]},
      { path: 'setup/safratti-travel-plan-add/:id', component: SafarattiTravelPlanAddComponent, canActivate:[AuthGuard]},
      { path: 'setup/safratti-promo-code', component: SafrattiPromoCodeComponent, canActivate:[AuthGuard]},
      { path: 'setup/safratti-promo-code-add', component: SafrattiPromoCodeAddComponent, canActivate:[AuthGuard]},
      
      { path: 'rcbook/:id', component: RcbookDetailComponent, canActivate: [AuthGuard] },
      { path: 'setup/vehicle-catalog', component: VehicleCatalogComponent, canActivate: [AuthGuard] },
      { path: 'setup/customers', component: NewCustomerImportComponent, canActivate: [AuthGuard] },
      { path: 'setup/customers/:id', component: NewCustomerDetailComponent, canActivate: [AuthGuard] },
      { path: 'renew-customers', component: RenewCustomersComponent, canActivate: [AuthGuard] },
      { path: 'renew-customers/:id', component: RenewCustomerDetailComponent, canActivate: [AuthGuard] },

      { path: 'collectibles', component: CollectiblesComponent, canActivate: [AuthGuard] },
      { path: 'policies', component: PoliciesComponent, canActivate: [AuthGuard] },
      { path: 'policies/:id', component: PolicyDetailComponent, canActivate: [AuthGuard] },
      { path: 'policies/policy-print/:id', component: B2bPolicyPrintComponent, canActivate: [AuthGuard] },
      { path: 'b2b-companies', component: B2BCompaniesComponent, canActivate: [AuthGuard] },
      { path: 'b2b-companies/:id', component: B2BCompanyDetailComponent, canActivate: [AuthGuard] },
      { path: 'stores-user', component: SpersonScoordinatorComponent, canActivate: [AuthGuard] },
      { path: 'stores-user/:id', component: SpersonScoordinatorDetailComponent, canActivate: [AuthGuard] },
      { path: 'store', component: StoreComponent, canActivate: [AuthGuard] },
      { path: 'store/:id', component: StoreDetailComponent, canActivate: [AuthGuard] },
      { path: 'user-dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },
      { path: 'setup/master-ratecard', component: MasterRatecardComponent, canActivate: [AuthGuard] },
      { path: 'companies/add-ratecard/:id', component: AddRatecardComponent, canActivate: [AuthGuard] },
    ]
  }
];


@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    LoginComponent,
    LeftMenuComponent,
    HeaderComponent,
    LogoutComponent,
    DashboardComponent,
    PurchaseDetailComponent,
    ConsumerRequestComponent,
    PolicyInfoComponent,
    PolicyStatusComponent,
    TplRequestStepperComponent,
    CustomerComponent,
    CustomerDetailComponent,
    CompaniesComponent,
    CompanyDetailComponent,
    PageHeaderComponent,
    FooterComponent,
    PolicyVerifyPrintComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    HomeComponent,
    SetupComponent,
    AgentListComponent,
    AgentAddComponent,
    ChangePasswordComponent,
    ConfirmEqualValidatorDirective,
    UsersComponent,
    UserDetailComponent,
    FeeSetupComponent,
    NumericDirective,
    DocEditXyModelComponent,
    ResetPasswordComponent,
    SecureLayoutComponent,
    TransportDepartmentDocComponent,
    TransportDocXyModelComponent,
    AutoFocusDirective,
    OrdersComponent,
    PointTransactionsComponent,
    OrderDetailComponent,
    BrokersComponent,
    BrokerDetailComponent,
    DocViewComponent,
    BackButtonDirective,
    DatePipe,
    ReceiptComponent,
    PrintLabelComponent,
    RedeemComponent,
    ReportsComponent,
    InsurerReportComponent,
    OrderSummaryReportComponent,
    IssuanceSummaryReportComponent,
    IssuanceReportComponent,
    UnissuedPoliciesReportComponent,
    RequestsReportComponent,
    ReferralComponent,
    ReferralAddComponent,
    ReferralReportComponent,
    ReferralUserReportComponent,
    RcbookDetailComponent,
    PasswordConfirmDialogComponent,
    EmailVerifiedComponent,
    VehicleCatalogComponent,
    NewCustomerImportComponent,
    NewCustomerDetailComponent,
    UsernamePasswordDialogComponent,
    ConfirmReasonDialogComponent,
    CollectiblesComponent,
    PoliciesComponent,
    PolicyDetailComponent,
    B2bPolicyPrintComponent,
    CompanyLoginComponent,
    UserLoginComponent,
    B2BCompaniesComponent,
    B2BCompanyDetailComponent,
    RedirectComponent,
    QrcodeComponent,
    CallbackPipe,
    B2BBulkUploadDialogComponent,
    SpersonScoordinatorComponent,
    SpersonScoordinatorDetailComponent,
    StoreComponent,
    StoreDetailComponent,
    MakePaymentDialogComponent,
    UserLoginComponent,
    UserDashboardComponent,
    RenewCustomersComponent,
    RenewCustomerDetailComponent,
    SetPasswordComponent,
    SelectLanguageComponent,
    MasterRatecardComponent,
    AddLimitDialogComponent,
    AddRatecardComponent,
    AddYearDialogComponent,
    ComprehensiveConsumerRequestComponent,
    CrDetailComponent,
    ForwardQuoteDialogComponent,
    ComprehensiveRequestStepperComponent,
    ComprehensivePolicyInfoComponent,
    ComprehensivePolicyStatusComponent,
    ComprehensiveUploadDocComponent,
    QuoteHistoryDetailsComponent,
    ComprehensiveOrderComponent,
    ComprehensiveOrderDetailComponent,
    VehicleDetailComponent,
    ComprehensiveIssuanceSummaryReportsComponent,
    ComprehensiveOrderSummaryReportsComponent,
    SafrattiRedirectComponent,
    ConsumerSafrattiRequestComponent,
    SafrattiOrdersComponent,
    SafrattiOrderDetailComponent,
    SafrattiPolicyDetailComponent,
    SafrattiPolicyHistoryComponent,
    SafrattiPolicyStatusComponent,
    SafrattiReportComponent,
    SafrattiIssuanceReportComponent,
    SafratiiFeeStructureComponent,
    SafrattiFeeStructureAddComponent,
    SafarattiTravelPlanListComponent,
    SafarattiTravelPlanAddComponent,
    SafrattiPolicyTravelerDetailsComponent,
    SafrattiPolicyComponent,
    SafrattiTravelPlanBenefitsAddComponent,
    SafrattiPolicyPaymentDetailComponent,
    SafrattiPolicyContactDetailComponent,
    SafrattiFeeStructureAgeComponent,
    SafrattiPromoCodeComponent,
    SafrattiPromoCodeAddComponent,
  ],
  imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule.forRoot(appRoutes, {}),
        RouterModule.forChild(appRoutes),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSidenavModule,
        MatSelectModule,
        MatButtonModule,
        BrowserAnimationsModule,
        NgbModule,
        MatTableModule,
        MatCardModule,
        MatListModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatMenuModule,
        MatDialogModule,
        BlobModule.forRoot(),
        LightboxModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatExpansionModule,
        HammerModule,
       AntdModules,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        AnalyticsModule,
        MatTreeModule,
        MatLegacySlideToggleModule,
        CarouselModule,
        GoogleTagManagerModule.forRoot({
          id: 'GTM-TQK5W78P',
        })
    ],
    providers: [Helpers, TokenService, StorageService, PoliciesService,
        RCBookService, RequestService, DashboardService, OrderService, PaymentService,
        HttpClient, AuthGuard, MatDatepickerModule,
        PointService,
        CustomerService, CompanyService, AgentService, AccountService,
        UsersService, TplService, TransportService, TplPurchaseService, InsurerReportService,
        OrderSummaryReportService, IssuanceReportService, IssuanceSummaryReportService,
        UnIssuedReportService, RequestsReportService, ReferralReportService, ReferralUserReportService,
        RenewCustomerService, DialogService, ReferralService, UserService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MatDialogRef, useValue:{}}
        // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
