import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UTMParameters } from "src/models/utm-parameters";

@Injectable({
  providedIn: "root",
})
export class UTMService {
  constructor(private http: HttpClient) {}

  registerConversion(utmParameters: UTMParameters) {
    return this.http.post(
      `http://apis.mymobibox.mobi/API/S2S_CallBack.aspx?Country=KW&clickid=${utmParameters.clickId?.trim()}&utm_content=${utmParameters.utmContent?.trim()}&utm_source=${utmParameters.utmSource?.trim()}&utm_campaign=${utmParameters.utmCampaign?.trim()}&utm_medium=${utmParameters.utmMedium?.trim()}&utm_term=mobibox`,
      null
    );
  }
}
