export class NewCustomer {
    /////////////////////////// User Details  /////////////////////////
    id?: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    phoneNumber1: string;
    phoneNumber2: string;
    phoneNumber3: string;
    phoneNumber4: string;
    countryCode: number;
    gender: string;
    dateOfBirth: string;
    email: string;
    ownerName: string;
    nationality:string;
    vehicleValue: number

    ////////////////// RC BOOK /////////////////
    plateNumber: string;
    rcCivilCardNumber: string;
    purposeOfLicense: string;
    rcOwnerName: string;
    rcNationality: string;
    referenceNumber: string;
    chassisNumber: string;
    carMake: string;
    carModel: string;
    carTrim: string;
    modelYear: number;
    fuelType: string;
    purposeOfLicenseId: number;
    passengers: number;
    rcExpiryDate: string;
    bodyStyle: string;
    color: string;
    weight: number;
    load: number;
    height: number;

    ////////////////////// Civil Card ///////////////////////
    civilCardExpiryDate: string;
    civilCardNumber: string;
    civilCardOwnerName: string;
    civilCardNationality: string;
    address: string;
    civilCardGender: string;
    block: string;
    street: string;
    buildingNumber: string;
    buildingKind: string;
    flatNumber: string;
    floorNumber: string;
    paciNumber: string;

    ////////////////////// Policy Details  //////////////////////////
    expiryDate: string;
    policyNumber: string;
    deliveryAddress: string;
    issueDate: string;
    startDate: string;
    deliveryDate: string;
    totalAmount: number;
    basePremium: number;
    convenienceFee: number;
    issueFee: number;
    supervisionFee: number;
    deliveryFee: number;
}
