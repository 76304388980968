import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { AuthRequest } from "../dto/request/auth-request";
import { AuthResponse } from "../dto/response/auth-response";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map, take } from "rxjs/operators";
import { SafrattiPolicy } from "src/models/sendPolicyEmail";
import { GetOfferRequest } from "src/models/get-offer-request";
import { GetOfferResponse } from "src/models/get-offer-response";
import { AccountResponse } from "src/models/account-response";
import { DataListRowRequest } from "src/models/data-list-row-request";
import { DataListResponse } from "src/models/data-list-response";
import { IssuePolicyRequest } from "src/dto/request/issue-policy-request";
import { GTSPolicyResponse } from "src/dto/response/gts-policy-response";
import { IssuePolicyResponse } from "src/dto/response/issue-policy-response";
import { PaymentCompleteRequest } from "src/dto/request/payment-complete-request";
import { NewUserResponse } from "src/dto/response/new-user-response";
import { SendEmailRequest } from "src/dto/request/send-email-request";
import { ZainPhoneValidationResponse } from "src/dto/response/zain-phone-validation-response";
import { ZainPhoneValidationRequest } from "src/dto/request/zain-phone-validation-request";
import { UpdatePolicyRequest } from "src/dto/request/update-policy-request";

@Injectable({
  providedIn: "root",
})
export class GTSService extends BaseService {
  constructor(helper: Helpers, private http: HttpClient) {
    super(helper);
  }

  authenticate(body: AuthRequest): Observable<AuthResponse> {
    return this.http
      .post<AuthResponse>(
        environment.gtsUrl + "identity/GetAccessToken",
        body,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  getOffers(body: GetOfferRequest): Observable<GetOfferResponse> {
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header = header.append("apiKey", "a");
    header = header.append("apiPassword", "b");
    header = header.append("Authorization", "Bearer " + this.helper.getToken());

    return this.http
      .post<GetOfferResponse>(environment.apiUrl + "Rating/GetOffers", body, {
        headers: header,
      })
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        }),
        catchError(this.handleError)
      );
  }

  getAccount(
    accountNumber: number,
    policyIssuer: string,
    token?: string
  ): Observable<AccountResponse> {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }
    return this.http
      .get<AccountResponse>(
        environment[policyIssuer].gtsCrmUrl +
          `Account/Get?accountNumber=${accountNumber}`,
        super.header(token)
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res).account;
        })
      );
  }

  getPlans(name: string, entityId: string): Observable<DataListResponse> {
    return this.http
      .get<DataListResponse>(
        environment.apiUrl + `datalist/Get?name=${name}&entityId=${entityId}`,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }
  getPolicyDetails(
    policyId: string,
    policyIssuer: string
  ): Observable<GTSPolicyResponse> {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }
    if (policyIssuer === "Safratti") {
      return of({
        request: {
          policyNumber: policyId,
        },
      });
    }
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header = header.append("apiKey", environment.apiKey);
    header = header.append("apiPassword", environment.apiPassword);
    header = header.append(
      "Authorization",
      "Bearer " + this.helper.getGtsBearerToken()
    );
    return this.http
      .get<IssuePolicyRequest>(
        environment.gtsUrl + `policy/Get?id=${policyId}`,
        { headers: header }
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  queryDataListRows(
    body: DataListRowRequest[],
    policyIssuer: string
  ): Observable<DataListResponse[]> {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }
    return this.http
      .post<DataListResponse[]>(
        environment[policyIssuer].gtsUrl + "datalist/QueryDataListRows",
        body,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  downloadTermsAndConditions(
    policyId: string,
    token: string
  ): Observable<HttpResponse<Blob>> {
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header.append("Authorization", "Bearer " + token);

    return this.http
      .get<Blob>(
        environment.gtsUrl + `printout/termsandconditions?id=${policyId}`,
        { headers: header, observe: "response", responseType: "blob" as "json" }
      )
      .pipe(take(1));
  }

  downloadCertificate(
    policyId: string,
    token: string
  ): Observable<HttpResponse<Blob>> {
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header.append("Authorization", "Bearer " + token);

    return this.http
      .get<Blob>(
        environment.gtsUrl +
          `printout/certificate?id=${policyId}&withoutHeader=false&separateCertificates=false&voucherIncluded=true`,
        { headers: header, observe: "response", responseType: "blob" as "json" }
      )
      .pipe(take(1));
  }

  downloadReciept(
    policyId: string,
    token: string
  ): Observable<HttpResponse<Blob>> {
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header.append("Authorization", "Bearer " + token);

    return this.http
      .get<Blob>(environment.gtsUrl + `printout/receipt?id=${policyId}`, {
        headers: header,
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(take(1));
  }

  sendEmail(body: SafrattiPolicy): Observable<void> {
    return this.http
      .post<void>(environment.apiUrl + "Email", body, super.header())
      .pipe(catchError(super.handleError));
  }

  sendEmailForGTS(body: SendEmailRequest, policyIssuer: string) {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }
    if (policyIssuer === "Safratti") {
      return this.http.post<SendEmailRequest>(
        environment[policyIssuer].gtsUrl + "Email",
        body,
        super.header()
      );
    }

    let header = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    if (this.helper.isAuthenticated()) {
      header = header.append(
        "Authorization",
        "Bearer " + this.helper.getGtsBearerToken()
      );
    }
    const urlencoded = new URLSearchParams();
    urlencoded.append("data", JSON.stringify(body));
    const headers = { headers: header };

    return this.http
      .post<SendEmailRequest>(
        environment[policyIssuer].gtsUrl + "Email",
        urlencoded,
        headers
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  getQueryListRequestBody(
    companyPrefix: string,
    companyCode: number
  ): DataListRowRequest[] {
    return [
      {
        name: companyPrefix + "GTSCountry",
        language: 1033,
        entityId: companyCode,
      },

      {
        name: companyPrefix + `GTSGender`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix + `GTSAreaOfTravel`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix + `GTSRelation`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix + `GTSDuration`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix + `GTSYesNo`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix + `GTSDestinationCountry`,
        language: 1033,
        entityId: companyCode,
      },
    ];
  }

  private _updatedPremiumPrice = new BehaviorSubject<number>(0);

  updatedPremiumPrice$ = this._updatedPremiumPrice.asObservable();

  updatePremiumPrice(price: number) {
    this._updatedPremiumPrice.next(price);
  }

  issuePolicy(
    body: IssuePolicyRequest,
    policyId: string,
    policyIssuer: string
  ): Observable<IssuePolicyResponse> {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }

    if (policyIssuer === "Safratti") {
      return of({
        id: policyId,
        email: "test@safratti.com",
      });
    }
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header = header.append("apiKey", environment.apiKey);
    header = header.append("apiPassword", environment.apiPassword);
    header = header.append(
      "Authorization",
      "Bearer " + this.helper.getGtsBearerToken()
    );
    return this.http
      .post<IssuePolicyResponse>(
        environment[policyIssuer].gtsUrl + "Policy/Issue",
        body,
        { headers: header }
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  completeTransaction(
    body: PaymentCompleteRequest,
    policyIssuer: string
  ): Observable<NewUserResponse> {
    if (policyIssuer !== "GTS" && policyIssuer !== "Safratti") {
      return throwError("Invalid policy issuer");
    }
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    header = header.append("apiKey", environment.apiKey);
    header = header.append("apiPassword", environment.apiPassword);
    return this.http.post<NewUserResponse>(
      environment.apiUrl + "Safratti/CompleteTransaction",
      body,
      {
        headers: header,
      }
    );
  }

  updatePolicyStatus(
    policyId: string,
    updatePolicyRequest: UpdatePolicyRequest
  ): Observable<void> {
    return this.http.post<void>(
      environment.apiUrl + `Safratti/UpdateStatus/${policyId}`,
      updatePolicyRequest,
      super.header()
    );
  }

  zainPhoneNumberValidation(
    body: ZainPhoneValidationRequest
  ): Observable<ZainPhoneValidationResponse> {
    return this.http.post<ZainPhoneValidationResponse>(
      environment.apiUrl + "Zain",
      body,
      super.header()
    );
  }
}
