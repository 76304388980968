import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { StorageService } from "src/services/storage.service";
import { NewCustomer } from "src/models/newcustomer";
import { TableComponent } from "../table.component";
import { RenewComprehensiveCustomerService } from "src/services/renew-comprehensive-customer.service";
@Component({
  selector: "app-renew-comprehensive-customers",
  templateUrl: "./renew-comprehensive-customers.component.html",
  styleUrls: ["./renew-comprehensive-customers.component.css"],
})
export class RenewComprehensiveCustomersComponent extends TableComponent<NewCustomer> {
  newCustomersFile: any;
  newCustomersEle: string;
  startExpiryDate: Date;
  endExpiryDate: Date;

  processing = false;

  displayedColumns: string[] = [
    "select",
    "id",
    "hasPurchased",
    "ownerName",
    "countryCode",
    "phoneNumber1",
    "phoneNumber2",
    "phoneNumber3",
    "phoneNumber4",
    "plateNumber",
    "carMake",
    "carModel",
    "modelYear",
    "nationality",
    "chassisNumber",
    "color",
    "expiryDate",
    "gender",
    "dateOfBirth",
    "email",
    "refferByName",
    "referrerId",
    "vehicleValue",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private router: Router,
    protected http: HttpClient,
    protected renewComprehensiveCustomerService: RenewComprehensiveCustomerService
  ) {
    super(renewComprehensiveCustomerService);
  }

  uploadCSV() {
    if (this.newCustomersFile !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("renew-comprehensivecustomer-csv")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/renew-comprehensivecustomer-csv/${
                d.getTime().toString() + ".csv"
              }${token.sasToken}`,
              this.newCustomersFile,
              "text/csv"
            )
            .subscribe({
              next: (res) => {
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
                location.reload()
              },
            });
        });
    }
  }

  onnewCustomers(event) {
    const file = event.target.files[0];
    this.newCustomersEle = file.name;
    this.newCustomersFile = file;
  }

  openCustomer(row) {
    this.router.navigate(["/renew-comprehensive-customers/" + row.id]);
  }

  hasPurchased(purchased) {
    return purchased ? "Yes" : "No";
  }

  getCount() {
    return this.renewComprehensiveCustomerService.getCount({
      filter: this.input.nativeElement.value,
      startExpiryDate: this.startExpiryDate,
      endExpiryDate: this.endExpiryDate,
    });
  }

  protected getData(): any {
    let pageSize = this.paginator.pageSize ? this.paginator.pageSize : 10;
    return {
      skip: this.paginator.pageIndex * pageSize,
      take: pageSize,
      filter: this.input.nativeElement.value,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === "desc",
      startExpiryDate: this.startExpiryDate,
      endExpiryDate: this.endExpiryDate,
    };
  }

  filter() {
    super.ngOnInit();
    
  }

  sendComprehensiveLink() {
   
    const formattedReqPayload = this.selection?.selected
      .map((selectedRow) => {
        const detectedLang = this.detectLanguage(selectedRow?.ownerName);
        return { id: selectedRow?.id, lang: detectedLang };
      })
      .filter((item) => item !== null);

    if (formattedReqPayload?.length === 0) {
      console.error("No valid payload generated");
      return;
    }    

    this.renewComprehensiveCustomerService
      .sendComprehensiveLink(formattedReqPayload)
      .subscribe(
        (res) => {
          this.snackBar.open(
            "The policy renewal reminder is sent successfully.",
            "CANCEL",
            {
              horizontalPosition: "right",
              duration: 4000,
            }
          );
        },
        (err) => {
          this.snackBar.open(err, "CANCEL", {
            horizontalPosition: "right",
            duration: 4000,
          });
        }
      );
  }

  detectLanguage(ownerName: string): string {
    if (!ownerName) return "unknown";

    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    if (arabicRegex.test(ownerName)) {
      return "ar";
    }
    const englishRegex = /^[A-Za-z0-9\s]+$/;
    if (englishRegex.test(ownerName)) {
      return "en";
    }
    return "unknown";
  }
}
