import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/services/account.service';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BranchResponse } from 'src/models/branch-response';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private accountService: AccountService, analytics: Analytics, private http:HttpClient) {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.accountService.getName(id).subscribe(res => {
      const redirectLink = `https://www.saiyarti.com/users/${id}/${encodeURIComponent(res.firstName + ' ' + res.lastName)}/unknown`;
      const branch_key = environment.branchKey;
  
      const requestPayload = {
        data: {
          "$og_url": redirectLink
        },
        branch_key: branch_key
      };
      
      this.http.post('https://api2.branch.io/v1/url', requestPayload, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).subscribe((response : BranchResponse) => {
        const redirectUrl = response.url;
        window.location.href = redirectUrl;
      })
    });
    logEvent(analytics, 'redirect', { referrerId: id });

  }

  ngOnInit() {
  }

}
