import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TplPurchaseService } from 'src/services/tpl-purchase.service';
import { StorageService } from 'src/services/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  constructor(private route: ActivatedRoute, private tplPurchaseService: TplPurchaseService,
              private storageService: StorageService, private sanitizer: DomSanitizer) { }


  order: any;
  safePDFDocumentUrl;
  transportPDFDocumentUrl;
  invoicePDFDocumentUrl;

  rcBookImageUrl: any;
  civilFrontImageUrl: any;
  civilBackImageUrl: any;

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getOrder(id);

  }

  policyEndDate() {
    const date = new Date(this.order.startDate);
    date.setDate( date.getDate() - 1 );
    return date.setFullYear(date.getFullYear() + this.order.tpl.period);
  }

  getOrder(id: any) {
    this.tplPurchaseService.getOrderById(id).subscribe(res => {
      // console.log(res);
      this.order = res;
      this.loadDocuments();
      this.loadImages();
    }, err => {
      console.log(err);
    });
  }

  loadDocuments(): void {

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'policies').subscribe((token: any) => {
      const encodedSasToken = encodeURIComponent(token.sasToken);
      let url = environment.cdnUrl + '/policies/'
      + this.route.snapshot.paramMap.get('id') + '_background.pdf' + encodedSasToken;
      this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
    + '_transport_background.pdf', 'policies').subscribe((token: any) => {
      const encodedSasToken = encodeURIComponent(token.sasToken);
      let url = environment.cdnUrl + '/policies/'
      + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + encodedSasToken;
      this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'policies').subscribe((token: any) => {
      const encodedSasToken = encodeURIComponent(token.sasToken);
      let url = environment.cdnUrl + '/policies/'
      + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + encodedSasToken;
      this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  loadImages() {
    const rcBook = this.order.rcBook.id + '.jpg';
    const civilFront = this.order.civilCard.id + '_front.jpg';
    const civilBack = this.order.civilCard.id + '_back.jpg';


    this.storageService.getSASToken(rcBook, 'rc-book').subscribe((token: any) => {
      this.rcBookImageUrl = environment.cdnUrl + '/rc-book/' + rcBook + token.sasToken;
    });

    this.storageService.getSASToken(civilFront, 'civil-card').subscribe((token: any) => {
      this.civilFrontImageUrl = environment.cdnUrl + '/civil-card/' + civilFront + token.sasToken;
    });

    this.storageService.getSASToken(civilBack, 'civil-card').subscribe((token: any) => {
      this.civilBackImageUrl = environment.cdnUrl + '/civil-card/' + civilBack + token.sasToken;
    });
  }
  
}
