import { Component, OnInit, ViewChild } from "@angular/core";
import { Lightbox } from "ngx-lightbox";
import { SafrattiRequestService } from "src/services/safratti-request.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { StorageService } from "src/services/storage.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Location } from "@angular/common";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { TravelerDetail } from "src/models/traveler-detail";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  PolicyDetails,
  PolicyDocumentServiceFactory,
} from "src/services/factory/policy-document-service-factory";
import { GTSService } from "src/services/gts.service";
import { StorageMember } from "src/models/storageMember";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataListRowResponse } from "src/models/data-list-row-response";
import { take } from "rxjs/operators";
import { OwlOptions } from "ngx-owl-carousel-o";
import { TplService } from "src/services/tpl.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { SafrattiPolicyContactDetailComponent } from "../safratti-policy-contact-detail/safratti-policy-contact-detail.component";
import { SafrattiPolicyPaymentDetailComponent } from "../safratti-policy-payment-detail/safratti-policy-payment-detail.component";
import { ModifyPolicy } from "src/models/modifyPolicy";
import { SafrattiPolicyTravelerDetailsComponent } from "../safratti-policy-traveler-details/safratti-policy-traveler-details.component";
import { SafrattiPolicy } from "src/models/sendPolicyEmail";
import { differenceInYears } from "date-fns";
import { GetOfferRequest } from "src/models/get-offer-request";
import { AccountResponse } from "src/models/account-response";
import { ComputedOption } from "src/models/computed-option";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-safratti-policy-detail",
  templateUrl: "./safratti-policy-detail.component.html",
  styleUrls: ["./safratti-policy-detail.component.css"],
})
export class SafrattiPolicyDetailComponent implements OnInit {
  formDisabled: boolean = true;
  albums: any = [];
  policyCopy: string;
  termsCondition: string;
  membership: string;
  invoice: string;
  thumbPolicyCopyImageUrl: any;
  thumbtermsConditionImageUrl: any;
  thumbMembershipImageUrl: any;
  thumbInvoiceImageUrl: any;

  policyFront: any;
  companies = [];

  userData: TravelerDetail;
  travelData: TravelerDetail[] = [];
  policyDataSource: MatTableDataSource<any>;
  policyHistory: any;
  phDisplayedColumns = ["policyId", "policyDate", "status"];
  safrattiPolicyId: any;
  membershipUrl?: SafeResourceUrl;
  showTripDetailsForm = true;
  showAddTravelerButton = false;
  currentMember?: StorageMember;
  isSportsActivityIncluded = "true";
  updatePremium = false;

  dataSource: MatTableDataSource<any>;
  safrattiPolicyForm: FormGroup;
  safrattiTravelForm: FormGroup;

  relations: DataListRowResponse[] = [];
  companyPrefix: string = "LSKW";
  companyCode: string = "5010";
  account?: AccountResponse;
  offers: ComputedOption[] = [];
  countries: DataListRowResponse[] = [];
  plans: DataListRowResponse[] = [];
  durations: DataListRowResponse[] = [];
  updatedPremiumPrice = 0;

  coverageZones: DataListRowResponse[] = [];

  destinationCountries: DataListRowResponse[] = [];

  currentPlan?: DataListRowResponse;
  currentResidenceCountry?: DataListRowResponse;
  currentDuration?: DataListRowResponse;
  currentDestinationCountry?: DataListRowResponse;
  currentCoverageZone?: DataListRowResponse;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  displayedColumns: string[] = [
    "serialNo",
    "relation",
    "firstName",
    "lastName",
    "dob",
    "passportNo",
    "sportsActivity",
  ];

  @ViewChild(SafrattiPolicyTravelerDetailsComponent)
  travelerDetail?: SafrattiPolicyTravelerDetailsComponent;

  @ViewChild(SafrattiPolicyPaymentDetailComponent)
  paymentForm?: SafrattiPolicyPaymentDetailComponent;

  @ViewChild(SafrattiPolicyContactDetailComponent)
  contactForm?: SafrattiPolicyContactDetailComponent;
  members: any;

  constructor(
    private lightbox: Lightbox,
    private safrattiService: SafrattiRequestService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private _snackBar: MatSnackBar,
    private location: Location,
    private router: Router,
    private sanitizer: DomSanitizer,
    private _policyDocumentServiceFactory: PolicyDocumentServiceFactory,
    private _gtsService: GTSService,
    private tplService: TplService,
    private http: HttpClient ,
    private dialog: MatDialog, ) {}

  onUpdatePremiumChanged(value: boolean) {
    this.updatePremium = value;
  }

  onPremiumUpdated(price: number) {
    this.updatedPremiumPrice = price;
  }

  ngOnInit(): void {

    this.loadData();
    this.safrattiPolicyForm = new FormGroup({
      startDate: new FormControl(""),
      endDate: new FormControl(""),
      sportsActivityIncluded: new FormControl(""),
    });

    this.safrattiTravelForm = new FormGroup({
      relation: new FormControl("", Validators.required),
      firstName: new FormControl("", Validators.required),
      middleName: new FormControl(""),
      lastName: new FormControl("", Validators.required),
      gender: new FormControl("", Validators.required),
      passportNo: new FormControl("", Validators.required),
      dateOfBirth: new FormControl("", Validators.required),
    });

    const id = +this.route.snapshot.paramMap.get("id");
    
    this.safrattiService.getPolicyDetail(id).subscribe(async (userData) => {
      this.userData = userData;

      const traveler = userData.travelersDetails[0];

      this.safrattiPolicyForm
        .get("startDate")
        ?.setValue(userData.travelPeriod.startDate);
      this.safrattiPolicyForm
        .get("endDate")
        ?.setValue(userData.travelPeriod.endDate);
      this.safrattiPolicyForm
        .get("sportsActivityIncluded")
        ?.setValue(userData.sportsActivityIncluded === "Yes" ? "Yes" : "No");

      this.safrattiPolicyForm.get("startDate").valueChanges.subscribe(() => {
        this.updatePremium = true;
        this.getOffers();
      });
      this.safrattiPolicyForm.get("endDate").valueChanges.subscribe(() => {
        this.updatePremium = true;
        this.getOffers();
      });
      this.safrattiPolicyForm
        .get("sportsActivityIncluded")
        .valueChanges.subscribe(() => {
          this.updatePremium = true;
          this.getOffers();
        });

      const policyDocumentService =
        this._policyDocumentServiceFactory.getPolicyDocumentServiceWithPolicyId(
          userData.policyId,'Safratti'
        );

      policyDocumentService.downloadPolicy((blob) => {
        const url = URL.createObjectURL(blob);
        this.thumbPolicyCopyImageUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });

      policyDocumentService.downloadMembershipCard((blob) => {
        const url = URL.createObjectURL(blob);
        this.membershipUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });

      policyDocumentService.downloadTermsAndConditions().subscribe((res) => {
        var file = new Blob([res.body], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        this.thumbtermsConditionImageUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });

      this.safrattiPolicyId = userData.policyId;
      this.policyCopy = this.safrattiPolicyId;
      this.termsCondition = this.safrattiPolicyId;
      this.membership = this.safrattiPolicyId;
      this.invoice = this.safrattiPolicyId;

      //get Policy history
      this.getPolicyHistory();
      this.storageService
        .getSASTokenForSafrattiInvoice(this.safrattiPolicyId)
        .subscribe((token) => {
          this.thumbInvoiceImageUrl =
            environment.cdnUrl +
            "/safratti-invoice/" +
            this.invoice +
            token.sasToken;
          const album = {
            src: this.thumbInvoiceImageUrl,
            caption: "Invoice",
            thumb: this.thumbInvoiceImageUrl,
          };
          this.albums[3] = album;
        });
    });
  
}

  convertImageToBase64(imageUrl: string): Observable<string> {
    return this.http
      .get(imageUrl, { responseType: "blob" })
      .pipe(switchMap((blob: Blob) => this.convertBlobToBase64(blob)));
  }

  convertBlobToBase64(blob: Blob): Observable<string> {
    const reader = new FileReader();

    return new Observable<string>((observer) => {
      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.readAsDataURL(blob);
    });
  }
  dateformate(val: any) {
    const date = new Date(val);
    if (date.toString() == "Invalid Date") {
      return (
        val.split("-")[2] + "/" + val.split("-")[1] + "/" + val.split("-")[0]
      );
    } else {
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  }

  getPolicyHistory(): void {
    this.safrattiService
      .getPolicyHistory(this.userData.id)
      .subscribe((pHList: any) => {
        this.policyHistory = pHList;
        this.policyDataSource = new MatTableDataSource(this.policyHistory);
      });
  }
  openQuoteHistory(userHistory: any) {
    this.router.navigate([
      "consumer-safratti-request/policy-history/" + userHistory.id,
    ]);
  }
  enableEditRequest(): void {
    this.formDisabled = !this.formDisabled;
  }
  onChangeEvent() {}
  goBack(): void {
    this.location.back();
  }
  approveRateCard() {}
  rejectRateCard() {}
  open(index: number): void {
    this.lightbox.open(this.albums, index);
  }

  getPessangerList() {}

  uploadPolicy() {
    let id = +this.route.snapshot.paramMap.get("id");
    const startDate = this.safrattiPolicyForm.get("startDate")?.value ?? "";

    const endDate = this.safrattiPolicyForm.get("endDate")?.value ?? "";

    const sportsActivityIncluded =
      this.safrattiPolicyForm.get("sportsActivityIncluded").value ?? "";
    const amountPaid =
      this.paymentForm.paymentDetailForm.get("amountPaid").value ?? "";
    const issueFee =
      this.paymentForm.paymentDetailForm.get("issueFee").value ?? "";
    const paymentToken =
      this.paymentForm.paymentDetailForm.get("paymentToken").value ?? "";
    const paymentId =
      this.paymentForm.paymentDetailForm.get("paymentId").value ?? "";

      
    const refund = +this.paymentForm.paymentDetailForm.get('refund').value ?? 0;
    const collection = +this.paymentForm.paymentDetailForm.get('collection').value ?? 0;
    const gtsPolicyNumber = this.paymentForm.paymentDetailForm.get('gtsPolicyNumber').value ?? ""

    const phoneNumber =
      this.contactForm.contactDetailForm.get("phoneNumber")?.value ?? "";
    const email = this.contactForm.contactDetailForm.get("email")?.value ?? "";
    const emergencyContactName =
      this.contactForm.contactDetailForm.get("emergencyContactName")?.value ??
      "";
    const emergencyContactNumber =
      this.contactForm.contactDetailForm.get("emergencyContactNumber")?.value ??
      "";

    let updatedMemberDetail = this.travelerDetail.members.map((member) => {
      let age;
      if (member.dateOfBirth.includes(" - ")) {
        const dobSplit = member.dateOfBirth.split(/[\s-,]+/);
        const day = dobSplit[2].padStart(2, "0");
        const month = dobSplit[1].padStart(2, "0");
        const dobDate = `${day}-${month}-${dobSplit[0]}`;
        const formatDate = new Date(dobDate);
        age = Math.abs(differenceInYears(new Date(), formatDate));
      } else {
        const memberDob = new Date(member.dateOfBirth.substring(0, 10));
        age = Math.abs(differenceInYears(new Date(), memberDob));
      }

      return {
        relation: member.relation,
        passportNo: member.passportNo,
        dateOfBirth: member.dateOfBirth,
        firstName: member.firstName,
        lastName: member.lastName,
        middleName: member.middleName,
        gender: member.gender,
        memberAge: age,
      };
    });

    const allMembers = [...updatedMemberDetail];
    const policyModifyDetail: ModifyPolicy = {
      id,
      travelPeriod: { startDate, endDate },
      sportsActivityIncluded: { kicSportActivity: sportsActivityIncluded },
      travelersDetails: allMembers,
      amountPaid,
      issueFee,
      paymentToken,
      paymentId,
      email,
      phoneNumber,
      emergencyContactName,
      emergencyContactNumber,
      refund,
      collection,
      gtsPolicyNumber
    };
    this.safrattiService
      .modifyPolicy(this.userData.id, policyModifyDetail)
      .pipe(take(1))

      .subscribe({
        next: (res) => {
          const policyIdUpdated = res.policyId;
          this._getRequiredDetailsForUpload(
            this.userData,
            policyModifyDetail,
            policyIdUpdated
          );
        },
      });
  }

  private _getRequiredDetailsForUpload(
    policyDto: any,
    policyModifyDetail: ModifyPolicy,
    policyIdUpdated?: number
  ) {
    this.safrattiService.getDefaultCompanyForSafratti().subscribe(
      (res: any) => {
        const safratti = res.safratti;
        const companyId = res.id;
        this.tplService.getSafrattiLabels(companyId).subscribe((res: any) => {
          const safrattiLables = res;
          const pdfUrl = environment.cdnUrl + "/company/" + safratti;
          this.convertImageToBase64(pdfUrl).subscribe((converted64) => {
            this._uploadTravelPolicyDocument(
              policyDto,
              policyModifyDetail,
              safrattiLables,
              converted64,
              policyIdUpdated
            );
          });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private _uploadTravelPolicyDocument(
    userData: any,
    policyModifyDetail: ModifyPolicy,
    safrattiLables: any[],
    policyBkgImg: string,
    policyIdUpdated?: number
  ) {
    const cardDetails: PolicyDetails = {
      policyId: userData.gtsPolicyId,
      policyNumber: userData.gtsPolicyId,
      startDate: policyModifyDetail.travelPeriod.startDate,
      endDate: policyModifyDetail.travelPeriod.endDate,
      name: `${policyModifyDetail.travelersDetails[0].firstName} ${policyModifyDetail.travelersDetails[0].lastName}`,
      token: userData.token,
      policyBkgImg: policyBkgImg,
      reference: userData.gtsPolicyId,
      agent: userData.agent,
      date: userData.issueDate.toString(),
      fullName:
        policyModifyDetail.travelersDetails[0].firstName +
        " " +
        policyModifyDetail.travelersDetails[0].lastName,
      passportNo: policyModifyDetail.travelersDetails[0].passportNo,
      countryofResidence: userData.countryOfResidence,
      gender: policyModifyDetail.travelersDetails[0].gender,
      dateofBirth:
        policyModifyDetail.travelersDetails[0].dateOfBirth.toString(),
      phone: policyModifyDetail.phoneNumber,
      email: policyModifyDetail.email,
      plan: userData.plan,
      coverageZone: userData.coverageZone,
      from: policyModifyDetail.travelPeriod.startDate.toString(),
      to: policyModifyDetail.travelPeriod.endDate.toString(),
      sportsActivities: {
        kicSportActivity: policyModifyDetail.sportsActivityIncluded,
      },
      destination: userData.destinationCountry,
      periodofCover: userData.periodOfCover,

      referencePosition: {
        x: safrattiLables[16]?.positionX,
        y: safrattiLables[16]?.positionY,
      },
      datePosition: {
        x: safrattiLables[3]?.positionX,
        y: safrattiLables[3]?.positionY,
      },
      agentPosition: {
        x: safrattiLables[0]?.positionX,
        y: safrattiLables[0]?.positionY,
      },
      fullNamePosition: {
        x: safrattiLables[8]?.positionX,
        y: safrattiLables[8]?.positionY,
      },
      passportNoPosition: {
        x: safrattiLables[12]?.positionX,
        y: safrattiLables[12]?.positionY,
      },
      countryofResidencePosition: {
        x: safrattiLables[1]?.positionX,
        y: safrattiLables[1]?.positionY,
      },
      genderPosition: {
        x: safrattiLables[9]?.positionX,
        y: safrattiLables[9]?.positionY,
      },
      dateofBirthPosition: {
        x: safrattiLables[4]?.positionX,
        y: safrattiLables[4]?.positionY,
      },
      phonePosition: {
        x: safrattiLables[14]?.positionX,
        y: safrattiLables[14]?.positionY,
      },
      emailPosition: {
        x: safrattiLables[7]?.positionX,
        y: safrattiLables[7]?.positionY,
      },
      planPosition: {
        x: safrattiLables[15]?.positionX,
        y: safrattiLables[15]?.positionY,
      },
      coverageZonePosition: {
        x: safrattiLables[2]?.positionX,
        y: safrattiLables[2]?.positionY,
      },
      fromPosition: {
        x: safrattiLables[18]?.positionX,
        y: safrattiLables[18]?.positionY,
      },
      toPosition: {
        x: safrattiLables[19]?.positionX,
        y: safrattiLables[19]?.positionY,
      },
      sportsActivitiesPosition: {
        x: safrattiLables[17]?.positionX,
        y: safrattiLables[17]?.positionY,
      },
      destinationPosition: {
        x: safrattiLables[6]?.positionX,
        y: safrattiLables[6]?.positionY,
      },
      periodofCoverPosition: {
        x: safrattiLables[13]?.positionX,
        y: safrattiLables[13]?.positionY,
      },
    };

    const policyDocumentService =
      this._policyDocumentServiceFactory.getPolicyDocumentService(cardDetails);

    policyDocumentService.uploadMembershipCard(() => {
      policyDocumentService.uploadPolicy(() => {
        this._snackBar.open("Travel Details Uploaded Successfully.", "Cancel");
        this.router
          .navigate([
            "/consumer-safratti-request/policy-detail/",
            policyIdUpdated,
          ])
          .then(() => {
            window.location.reload();
          });
      });
    });
  }

  cancelPolicy() {
    const diloagRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "Are you sure you want to cancel policy?",
      },
    });
    diloagRef.afterClosed().subscribe((result) => {
      const id = +this.route.snapshot.paramMap.get("id");
      if (result === "true") {
        this.safrattiService.cancelPolicy(id).subscribe((_) => {
          this._snackBar.open("Policy cancelled successfully.");
          this.router.navigate(['/consumer-safratti-request'])
        });
      }
    });
  }

  regenerateDocuments() {
    const id = +this.route.snapshot.paramMap.get("id");
    const startDate = new Date(
      this.safrattiPolicyForm.get("startDate")?.value ?? ""
    );
    const endDate = new Date(
      this.safrattiPolicyForm.get("endDate")?.value ?? ""
    );
    const sportsActivityIncluded =
      this.safrattiPolicyForm.get("sportsActivityIncluded").value ?? "";
    const amountPaid =
      this.paymentForm.paymentDetailForm.get("amountPaid").value ?? "";
    const issueFee =
      this.paymentForm.paymentDetailForm.get("issueFee").value ?? "";
    const paymentToken =
      this.paymentForm.paymentDetailForm.get("paymentToken").value ?? "";
    const paymentId =
      this.paymentForm.paymentDetailForm.get("paymentId").value ?? "";

    const relation = this.travelerDetail.members[0].relation;
    const firstName = this.travelerDetail.members[0].firstName;
    const middleName = this.travelerDetail.members[0].middleName;
    const lastName = this.travelerDetail.members[0].lastName;
    const dateOfBirth = this.travelerDetail.members[0].dateOfBirth;
    const passportNo = this.travelerDetail.members[0].passportNo;
    const gender = this.travelerDetail.members[0].gender;

    const phoneNumber =
      this.contactForm.contactDetailForm.get("phoneNumber")?.value ?? "";
    const email = this.contactForm.contactDetailForm.get("email")?.value ?? "";
    const emergencyContactName =
      this.contactForm.contactDetailForm.get("emergencyContactName")?.value ??
      "";
    const emergencyContactNumber =
      this.contactForm.contactDetailForm.get("emergencyContactNumber")?.value ??
      "";

    let updatedMemberDetail = this.travelerDetail.members.map(() => {
      return {
        ...this.travelerDetail.members,
        relation: relation,
        passportNo: passportNo,
        dateOfBirth: dateOfBirth,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        gender: gender,
      };
    });

    const policyModifyDetail: ModifyPolicy = {
      id,
      travelPeriod: { startDate, endDate },
      sportsActivityIncluded: { kicSportActivity: sportsActivityIncluded },
      travelersDetails: updatedMemberDetail,
      amountPaid,
      issueFee,
      paymentToken,
      paymentId,
      email,
      phoneNumber,
      emergencyContactName,
      emergencyContactNumber,
    };
    this._getRequiredDetailsForUpload(this.userData, policyModifyDetail);
  }

  sendEmail() {
    const safrattiPolicyEmail: SafrattiPolicy = {
      name:
        this.userData.travelersDetails[0].firstName +
        " " +
        this.userData.travelersDetails[0].lastName,
      to: [this.userData.email],
      policyId: this.userData.policyId,
    };
    this._gtsService.sendEmail(safrattiPolicyEmail).subscribe({
      next: () => {
        this._snackBar.open("Email sent successfully.", "Cancel");
      },
    });
  }

  private loadData() {
    const body = environment.authenticate;

    this._gtsService.authenticate(body).subscribe((response) => {
      this._gtsService.getAccount(response.account, 'Safratti').subscribe((response) => {
        this.account = response;

        if ((this.account.insuranceCompanies?.length ?? 0) > 0) {
          this.companyPrefix =
            this.account.insuranceCompanies![0].insuranceCompanyShortName;
          this.companyCode =
            this.account.insuranceCompanies![0].insuranceCompanyCode;
        } else {
          this.companyPrefix = this.account.insuranceCompanyShortName;
          this.companyCode = this.account.insuranceCompanyCode;
        }

        this._gtsService
          .getPlans(`${this.companyPrefix}GTSProduct`, this.companyCode)
          .subscribe((response) => {
            this.plans = response.data.filter((x) => x.code === "ZAIN");
            if (this.plans.length > 0) {
              this.currentPlan = this.plans[0];
            }
          });

        this._gtsService
          .queryDataListRows(
            this._gtsService.getQueryListRequestBody(
              this.companyPrefix,
              +this.companyCode
            ),'Safratti'
          )
          .subscribe((response) => {
            response.forEach((element) => {
              if (element.dataListName === `${this.companyPrefix}GTSCountry`) {
                this.countries = element.data;
                const kuwaitCountry = this.countries.filter(
                  (x) => (x?.code?.toLowerCase() ?? "") === "kuwait"
                );
                if (kuwaitCountry.length > 0) {
                  this.currentResidenceCountry = kuwaitCountry[0];
                }
              } else if (
                element.dataListName === `${this.companyPrefix}GTSDuration`
              ) {
                this.durations = element.data.filter(
                  (x) =>
                    (x.planCode?.indexOf("ZAIN") ?? -1) > -1 ||
                    (x.planCode?.indexOf("All") ?? -1) > -1
                );

                this.durations = this.durations
                  .map((x) => {
                    if (x.unit.toLowerCase() === "years") {
                      x.periodInDays = (x.period ?? 1) * 365;
                    } else {
                      x.periodInDays = x.period;
                    }
                    return x;
                  })
                  .sort((x, y) => {
                    return (x.periodInDays ?? 0) < (y.periodInDays ?? 0)
                      ? -1
                      : 1;
                  });
              } else if (
                element.dataListName === `${this.companyPrefix}GTSAreaOfTravel`
              ) {
                this.coverageZones = element.data.filter(
                  (x) => (x.planCode?.indexOf("ZAIN") ?? -1) > -1
                );
                if (this.coverageZones.length > 0) {
                  this.currentCoverageZone = this.coverageZones[0];
                }
              } else if (
                element.dataListName ===
                `${this.companyPrefix}GTSDestinationCountry`
              ) {
                this.destinationCountries = element.data.filter(
                  (x) => x.areaCode === "WW"
                );
                if (this.destinationCountries.length > 0) {
                  this.currentDestinationCountry = this.destinationCountries[0];
                }
              } else if (
                element.dataListName === `${this.companyPrefix}GTSRelation`
              ) {
                this.relations = element.data;
              }
            });
          });
      });
    });
  }

  getOffers() {
    const travelStartDate = new Date(
      this.safrattiPolicyForm.get("startDate").value
    );
    const travelEndDate = new Date(
      this.safrattiPolicyForm.get("endDate")?.value
    );
    const questionResponse: any = this.safrattiPolicyForm.get(
      "sportsActivityIncluded"
    )?.value;
    var birthDate: Date | undefined = undefined;
    var mainMembers =  this.travelerDetail.members.filter((x) => x.relation === "main");
    if (mainMembers.length > 0) {
      const dob = mainMembers[0].dateOfBirth.split(" - ");
      birthDate = new Date(+dob[0], +dob[1] - 1, +dob[2]);
    }

    if (!travelStartDate || !travelEndDate || !birthDate || !questionResponse) {
      return;
    }

    if (travelStartDate && travelEndDate) {
      let timeDiff = travelStartDate.getTime() - travelEndDate.getTime();
      let differenceInDays = Math.abs(
        Math.floor(timeDiff / (1000 * 3600 * 24))
      );

      for (let index = 0; index < this.durations.length; index++) {
        const duration = this.durations[index];
        if (
          duration.periodInDays &&
          duration.periodInDays >= differenceInDays
        ) {
          this.currentDuration = duration;
          break;
        }
      }
    }

    let timeDiff = Math.abs(Date.now() - birthDate.getTime());
    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    var offerRequest: GetOfferRequest = {
      Metadata: {
        From: `${travelStartDate.getFullYear()} - ${
          travelStartDate.getMonth() + 1
        } - ${travelStartDate.getDate()}`,
        To: `${travelEndDate.getFullYear()} - ${
          travelEndDate.getMonth() + 1
        } - ${travelEndDate.getDate()}`,
      },
      ProductId: 3,
      Lcid: 1033,
      Entities: [+this.companyCode],
      Dimensions: [
        {
          Name: "SMI",
          PolicyLevel: {
            Duration: this.currentDuration?.code ?? "7days",
            MemberCount:  this.travelerDetail.members.length,
            HasSpouse:
            this.travelerDetail.members.filter(
                (x) => String(x.relation).toLowerCase() === "spouse"
              ).length > 0,
            Plan: this.currentPlan?.code,
            AreaOfTravel: this.currentCoverageZone?.code,
            EligibleAreas: this.currentCoverageZone?.code,
            Markup: 0,
            Country: "kuwait",
            CountryOfResidence: this.currentResidenceCountry?.code,
            DestinationCountry: this.currentDestinationCountry?.code,
            Accountnb: this.account?.accountNumber,
            Insurancecompanynb: this.companyCode,
            IsLifeCoverApplicable: true,
            MainAge: age,
          },
          Members:  this.travelerDetail.members.map((x) => {
            let age;
            if (x.dateOfBirth.includes(" - ")) {
              const dob = x.dateOfBirth.split(" - ");
              birthDate = new Date(+dob[0], +dob[1] - 1, +dob[2]);
              let timeDiff = Math.abs(
                Date.now() - new Date(birthDate).getTime()
              );
              age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
            } else {
              const dob = new Date(x.dateOfBirth.substring(0, 10));
              let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
              age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
            }
            return {
              Id: 0,
              SportsActivity: questionResponse === "true" ? "yes" : "no",
              MemberAge: age,
            };
          }),
        },
        {
          Name: "Contract",
          Properties: {
            IsEndorsement: "false",
            IsGettingOffers: "true",
          },
        },
        {
          Name: "Questionnaire",
          Properties: {
            kicsportactivity: questionResponse === "true" ? "yes" : "no",
          },
        },
      ],
    };
    this._gtsService.getOffers(offerRequest).subscribe({
      next: (x) => {
        this.offers = x.computedOptions;
        const updatedPremiumPrice = this.offers[0]?.price;
        this._gtsService.updatePremiumPrice(updatedPremiumPrice);
      },
      error: () => {},
    });
  }
}
