import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SafrattiRequestService } from "src/services/safratti-request.service";
import { Location } from "@angular/common";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { OrderService } from "src/services/order.service";
import { SafrattiOrderDetail } from "src/models/safratti-order-detail";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { OwlOptions } from "ngx-owl-carousel-o";
import {
  PolicyDetails,
  PolicyDocumentServiceFactory,
} from "src/services/factory/policy-document-service-factory";
import { GTSService } from "src/services/gts.service";
import { environment } from "src/environments/environment";
import { CompaniesService } from "src/services/companies.service";
import { CompanyService } from "src/services/company.service";
import { TplService } from "src/services/tpl.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SafrattiPolicy } from "src/models/sendPolicyEmail";
import { SafrattiPolicyDocumentService } from "src/services/safratti-policy-document.service";
import { GTSPolicyDocumentService } from "src/services/gts-policy-document.service";
import { StorageService } from "src/services/storage.service";
import { NgZone } from "@angular/core";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-safratti-policy-status",
  templateUrl: "./safratti-policy-status.component.html",
  styleUrls: ["./safratti-policy-status.component.css"],
})
export class SafrattiPolicyStatusComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  constructor(
    private safrattiService: SafrattiRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private order: OrderService,
    private policyDocumentServiceFactory: PolicyDocumentServiceFactory,
    private _gtsService: GTSService,
    private _companyService: CompanyService,
    private tplService: TplService,
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    private _policyDocumentServiceFactory: PolicyDocumentServiceFactory,
    private _storageService: StorageService,
    private zone: NgZone
  ) {}

  policyPDFDocumentUrl?: string;
  termsConditionPDFDocumentUrl?: string;
  membershipPDFDocumentUrl?: string;
  invoicePDFDocumentUrl?: string;
  step = 0;
  pageTitle = "Safratti Request";
  safrattiWidth: any;
  safrattiHeight: any;
  orderDetail: SafrattiOrderDetail;
  policyCopyUrl: SafeResourceUrl;
  termsAndCondition: SafeResourceUrl;
  membershipUrl: SafeResourceUrl;
  isGTSPolicy =  false;

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get("id");

    const state: any = this.location.getState();
    if (state && state.policyIssuer) {
      const policyIssuer = state.policyIssuer;
      this.isGTSPolicy = policyIssuer === 'Safratti'? false : true;
      this.order.getOrderDetail(id).subscribe(async (orderDetail) => {
        this.orderDetail = orderDetail;

        window.localStorage["companyId"] = orderDetail.companyId;

        const body = environment.authenticate;
        this._gtsService.authenticate(body).subscribe((res) => {
          const gtsMembershipCardService =
            this.policyDocumentServiceFactory.getPolicyDocumentServiceWithPolicyId(
              policyIssuer === "Safratti"
                ? orderDetail.policyId
                : orderDetail.gtsPolicyId , policyIssuer
            );
          gtsMembershipCardService.downloadPolicy((blob) => {
            const url = URL.createObjectURL(blob);
            this.zone.run(() => {
              this.policyCopyUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
          });

          gtsMembershipCardService.downloadMembershipCard((blob) => {
            const url = URL.createObjectURL(blob);
            this.zone.run(() => {
            this.membershipUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
          });
          gtsMembershipCardService.downloadTermsAndConditions().subscribe((res) => {
            const file = new Blob([res.body], { type: "application/pdf" });
            const url = URL.createObjectURL(file);
            this.termsAndCondition =
              this.sanitizer.bypassSecurityTrustResourceUrl(url);
          });
        });
        
      });
    }
  }

  convertImageToBase64(imageUrl: string): Observable<string> {
    return this.http
      .get(imageUrl, { responseType: "blob" })
      .pipe(switchMap((blob: Blob) => this.convertBlobToBase64(blob)));
  }

  convertBlobToBase64(blob: Blob): Observable<string> {
    const reader = new FileReader();

    return new Observable<string>((observer) => {
      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.readAsDataURL(blob);
    });
  }

  regenerateDocuments() {
    const id = +this.route.snapshot.paramMap.get("id");
    const body = environment.authenticate;

    this._gtsService.authenticate(body).subscribe((res) => {
      this.safrattiService.getPolicyDetail(id).subscribe((policyDto) => {
        policyDto.token = res.token;
        this._getRequiredDetailsForUpload(policyDto);
      });
    });
  }

  private _getRequiredDetailsForUpload(policyDto: any) {
    this.safrattiService.getDefaultCompanyForSafratti().subscribe(
      (res: any) => {
        const safratti = res.safratti;
        const companyId = res.id;
        this.tplService.getSafrattiLabels(companyId).subscribe((res: any) => {
          const safrattiLables = res;
          const pdfUrl = environment.cdnUrl + "/company/" + safratti;
          this.convertImageToBase64(pdfUrl).subscribe((converted64) => {
            this._uploadTravelPolicyDocument(
              policyDto,
              safrattiLables,
              converted64
            );
          });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private _uploadTravelPolicyDocument(
    userData: any,
    safrattiLables: any[],
    policyBkgImg: string
  ) {
    const cardDetails: PolicyDetails = {
      policyId: userData.gtsPolicyId,
      policyNumber: this.orderDetail.gtsPolicyId,
      startDate: userData.travelPeriod.startDate,
      endDate: userData.travelPeriod.endDate,
      name: `${userData.travelersDetails[0].firstName} ${userData.travelersDetails[0].lastName}`,
      token: userData.token,
      policyBkgImg: policyBkgImg,
      reference: userData.gtsPolicyId,
      agent: this.orderDetail.agent,
      date: this.orderDetail.issueDate.toString(),
      fullName:
        userData.travelersDetails[0].firstName +
        " " +
        userData.travelersDetails[0].lastName,
      passportNo: userData.travelersDetails[0].passportNo,
      countryofResidence: userData.countryOfResidence,
      gender: userData.travelersDetails[0].gender,
      dateofBirth: userData.travelersDetails[0].dateOfBirth,
      phone: userData.phoneNumber,
      email: userData.email,
      plan: userData.plan,
      coverageZone: userData.coverageZone,
      from: userData.travelPeriod.startDate,
      to: userData.travelPeriod.endDate,
      sportsActivities: userData.sportsActivityIncluded,
      destination: userData.destinationCountry,
      periodofCover: this.orderDetail.periodOfCover,

      referencePosition: {
        x: safrattiLables[16]?.positionX,
        y: safrattiLables[16]?.positionY,
      },
      datePosition: {
        x: safrattiLables[3]?.positionX,
        y: safrattiLables[3]?.positionY,
      },
      agentPosition: {
        x: safrattiLables[0]?.positionX,
        y: safrattiLables[0]?.positionY,
      },
      fullNamePosition: {
        x: safrattiLables[8]?.positionX,
        y: safrattiLables[8]?.positionY,
      },
      passportNoPosition: {
        x: safrattiLables[12]?.positionX,
        y: safrattiLables[12]?.positionY,
      },
      countryofResidencePosition: {
        x: safrattiLables[1]?.positionX,
        y: safrattiLables[1]?.positionY,
      },
      genderPosition: {
        x: safrattiLables[9]?.positionX,
        y: safrattiLables[9]?.positionY,
      },
      dateofBirthPosition: {
        x: safrattiLables[4]?.positionX,
        y: safrattiLables[4]?.positionY,
      },
      phonePosition: {
        x: safrattiLables[14]?.positionX,
        y: safrattiLables[14]?.positionY,
      },
      emailPosition: {
        x: safrattiLables[7]?.positionX,
        y: safrattiLables[7]?.positionY,
      },
      planPosition: {
        x: safrattiLables[15]?.positionX,
        y: safrattiLables[15]?.positionY,
      },
      coverageZonePosition: {
        x: safrattiLables[2]?.positionX,
        y: safrattiLables[2]?.positionY,
      },
      fromPosition: {
        x: safrattiLables[18]?.positionX,
        y: safrattiLables[18]?.positionY,
      },
      toPosition: {
        x: safrattiLables[19]?.positionX,
        y: safrattiLables[19]?.positionY,
      },
      sportsActivitiesPosition: {
        x: safrattiLables[17]?.positionX,
        y: safrattiLables[17]?.positionY,
      },
      destinationPosition: {
        x: safrattiLables[6]?.positionX,
        y: safrattiLables[6]?.positionY,
      },
      periodofCoverPosition: {
        x: safrattiLables[13]?.positionX,
        y: safrattiLables[13]?.positionY,
      },
    };

    const policyDocumentService =
      this._policyDocumentServiceFactory.getPolicyDocumentService(cardDetails);

    policyDocumentService.uploadMembershipCard(() => {
      policyDocumentService.uploadPolicy(() => {
        this._snackBar.open("Documents Uploaded Successfully.", "Cancel");
      });
    });
  }

  sendEmail() {
    const id = +this.route.snapshot.paramMap.get("id");
    const body = environment.authenticate;

    this._gtsService.authenticate(body).subscribe((res) => {
      this.safrattiService.getPolicyDetail(id).subscribe((policyData) => {
        const safrattiPolicyEmail: SafrattiPolicy = {
          name:
            policyData.travelersDetails[0].firstName +
            " " +
            policyData.travelersDetails[0].lastName,
          to: [policyData.email],
          policyId: policyData.policyId,
        };
        this._gtsService.sendEmail(safrattiPolicyEmail).subscribe({
          next: () => {
            this._snackBar.open("Email sent successfully.", "Cancel");
          },
        });
      });
    });
  }

  goBack() {
    this.location.back();
  }
  goMofify() {
    const id = +this.route.snapshot.paramMap.get("id");
    this.router.navigate(["consumer-safratti-request/policy-detail/" + id]);
  }
  cancelPolicy() {
    const diloagRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "Are you sure you want to cancel policy?",
      },
    });
    diloagRef.afterClosed().subscribe((result) => {
      const id = +this.route.snapshot.paramMap.get("id");
      if (result === "true") {
        this.safrattiService.cancelPolicy(id).subscribe((_) => {
          this._snackBar.open("Policy cancelled successfully.");
          this.location.back();
        });
      }
    });
  }

  updateRewardCreditStatus(isApproved: boolean) {
    this.safrattiService
      .updateRewardCredit(this.orderDetail.id, isApproved)
      .subscribe({
        next: () => {
          if (isApproved) {
            this._snackBar.open("Reward Credited Successfully.");
            window.location.reload();
          } else {
            this._snackBar.open("Reward credit rejected.");
            window.location.reload();
          }
        },
        error: () => {
          alert("Error in updating reward credit status");
        },
      });
  }
}
