import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TableComponent } from "../table.component";
import { SelectionModel } from "@angular/cdk/collections";
import { Router } from "@angular/router";
import { SafrattiRequestService } from "src/services/safratti-request.service";
import { TravelerDetail } from "src/models/traveler-detail";
import { GTSService } from "src/services/gts.service";
import { IssuePolicyRequest } from "src/dto/request/issue-policy-request";
import { DataListRowResponse } from "src/models/data-list-row-response";
import { environment } from "src/environments/environment";
import { AccountResponse } from "src/models/account-response";
import { DataListRowRequest } from "src/models/data-list-row-request";
import { AuthRequest } from "src/dto/request/auth-request";
import { Helpers } from "src/helpers/helpers";
import * as amplitude from "@amplitude/analytics-browser";
import { Identify, identify } from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { SendEmailRequest } from "src/dto/request/send-email-request";
import { UTMService } from "src/services/utm-service";
import { TransactionDetails } from "src/dto/request/transaction-details";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { UpdatePolicyRequest } from "src/dto/request/update-policy-request";
import { UTMParameters } from "src/models/utm-parameters";

declare global {
  interface Window {
    fbq: (
      event: string,
      action: string,
      details?: { currency: string; value: number },
      pixelId?: string
    ) => void;
    snaptr: (
      event: string,
      action: string,
      details?: { currency: string; value: number }
    ) => void;
    ttq: (
      event: string,
      action: string,
      details?: { currency: string; value: number }
    ) => void;
  }
}
export class Customer {
  id: number;
  customerName: string;
  userName: string;
  requestType: string;
  vehicleType: string;
  date: string;
  amount: string;
  status: string;
}

@Component({
  selector: "app-consumer-safratti-request",
  templateUrl: "./consumer-safratti-request.component.html",
  styleUrls: ["./consumer-safratti-request.component.css"],
})
export class ConsumerSafrattiRequestComponent extends TableComponent<Request> {
  pageTitle = "Request";
  requestsArray = [];
  error: any;
  displayedColumns: string[] = [
    "id",
    "customerName",
    "email",
    "phoneNumber",
    "policyType",
    "policyNumber",
    "requestType",
    "date",
    "status",
    "basePremium",
    "policyFee",
    "totalFee",
    "totalPremium",
    "convenienceFee",
    "netAmount",
    "discountAmount",
    "promotionName",
    "paymentReference",
    "refund",
    "collection",
    "source",
    "issuer",
  ];
  selection = new SelectionModel<any>(true, []);
  currentPlan?: DataListRowResponse;
  currentResidenceCountry?: DataListRowResponse;
  currentDuration?: DataListRowResponse;
  currentDestinationCountry?: DataListRowResponse;
  currentCoverageZone?: DataListRowResponse;
  relations: DataListRowResponse[] = [];
  countries: DataListRowResponse[] = [];
  plans: DataListRowResponse[] = [];
  durations: DataListRowResponse[] = [];
  coverageZones: DataListRowResponse[] = [];
  destinationCountries: DataListRowResponse[] = [];
  filteredDestinationCountries: DataListRowResponse[] = [];
  account?: AccountResponse;
  companyPrefix: string = "LSKW";
  companyCode: string = "5010";
  authRequest: AuthRequest = environment.authenticate;
  isZainPhoneNumber = false;

  constructor(
    private safrattiService: SafrattiRequestService,
    private _gtsService: GTSService,
    private _utmService: UTMService,
    private router: Router,
    private _helper: Helpers,
    private _gtmService: GoogleTagManagerService,
    private cdr: ChangeDetectorRef
  ) {
    super(safrattiService);

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    });
    amplitude.add(sessionReplayTracking);
    amplitude.init("3155389f4e6e37e08805a1a47a47a4c4", undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
    this._gtsService.authenticate(this.authRequest).subscribe((response) => {
      this._helper.setGtsBearerToken(response.token);
      this._helper.setAgentFullName(response.userFullName);
      this._helper.setAgentCode(response.agentCode);
      this._gtsService
        .getAccount(response.account, "GTS", response.token)
        .subscribe((response) => {
          this.account = response;

          if ((this.account.insuranceCompanies?.length ?? 0) > 0) {
            this.companyPrefix =
              this.account.insuranceCompanies![0].insuranceCompanyShortName;
            this.companyCode =
              this.account.insuranceCompanies![0].insuranceCompanyCode;
          } else {
            this.companyPrefix = this.account.insuranceCompanyShortName;
            this.companyCode = this.account.insuranceCompanyCode;
          }

          this._gtsService
            .getPlans(`${this.companyPrefix}GTSProduct`, this.companyCode)
            .subscribe((response) => {
              this.plans = response.data.filter((x) => x.code === "ZAIN");
              if (this.plans.length > 0) {
                this.currentPlan = this.plans[0];
              }
            });

          this._gtsService
            .queryDataListRows(
              this.getQueryListRequestBody(
                this.companyPrefix,
                +this.companyCode
              ),
              "GTS"
            )
            .subscribe((response) => {
              response.forEach((element) => {
                if (
                  element.dataListName === `${this.companyPrefix}GTSCountry`
                ) {
                  this.countries = element.data;
                  const kuwaitCountry = this.countries.filter(
                    (x) => (x?.code?.toLowerCase() ?? "") === "kuwait"
                  );
                  if (kuwaitCountry.length > 0) {
                    this.currentResidenceCountry = kuwaitCountry[0];
                  }
                } else if (
                  element.dataListName === `${this.companyPrefix}GTSDuration`
                ) {
                  this.durations = element.data.filter(
                    (x) =>
                      (x.planCode?.indexOf("ZAIN") ?? -1) > -1 ||
                      (x.planCode?.indexOf("All") ?? -1) > -1
                  );
                  this.durations = this.durations
                    .map((x) => {
                      if (x.unit.toLowerCase() === "years") {
                        x.periodInDays = (x.period ?? 1) * 365;
                      } else {
                        x.periodInDays = x.period;
                      }
                      return x;
                    })
                    .sort((x, y) => {
                      return (x.periodInDays ?? 0) < (y.periodInDays ?? 0)
                        ? -1
                        : 1;
                    });
                } else if (
                  element.dataListName ===
                  `${this.companyPrefix}GTSAreaOfTravel`
                ) {
                  this.coverageZones = element.data.filter(
                    (x) => (x.planCode?.indexOf("ZAIN") ?? -1) > -1
                  );
                  if (this.coverageZones.length > 0) {
                    this.currentCoverageZone = this.coverageZones[0];
                  }
                } else if (
                  element.dataListName ===
                  `${this.companyPrefix}GTSDestinationCountry`
                ) {
                  this.destinationCountries = element.data.filter(
                    (x) => x.areaCode === "WW"
                  );
                  if (this.destinationCountries.length > 0) {
                    this.currentDestinationCountry =
                      this.destinationCountries[0];
                  }
                } else if (
                  element.dataListName === `${this.companyPrefix}GTSRelation`
                ) {
                  this.relations = element.data;
                  const mainRelations = this.relations.filter(
                    (x) => (x?.code?.toLowerCase() ?? "") === "main"
                  );
                }
              });
            });
        });
    });
  }

  getQueryListRequestBody(
    companyPrefix: string,
    companyCode: number
  ): DataListRowRequest[] {
    return [
      {
        name: `${this.companyPrefix}GTSCountry`,
        language: 1033,
        entityId: companyCode,
      },

      {
        name: `${this.companyPrefix}GTSGender`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: `${this.companyPrefix}GTSAreaOfTravel`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: `${this.companyPrefix}GTSRelation`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: `${this.companyPrefix}GTSDuration`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: `${this.companyPrefix}GTSYesNo`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: `${this.companyPrefix}GTSDestinationCountry`,
        language: 1033,
        entityId: companyCode,
      },
    ];
  }

  openPurchaseDetail(userdata: TravelerDetail): void {
    if (userdata.status == "POLICY_ISSUED" || userdata.status == "ISSUED") {
      this.router.navigate(
        ["consumer-safratti-request/policy-status/" + userdata.id],
        { state: { policyIssuer: userdata.issuer } }
      );
    }
    if (userdata.status == "POLICY_MODIFIED") {
      this.router.navigate([
        "consumer-safratti-request/policy-detail/" + userdata.id,
      ]);
    } else {
    }
  }

  formatIssueDate(val: any) {
    const [datePart, timePart] = val.split("T");

    const utcDate = new Date(`${datePart}T${timePart}Z`);

    const kuwaitOffset = 3 * 60 * 60 * 1000; // Kuwait is UTC+3 hours
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitOffset);

    const hours = String(kuwaitDate.getUTCHours()).padStart(2, "0");
    const minutes = String(kuwaitDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(kuwaitDate.getUTCSeconds()).padStart(2, "0");
    const kuwaitTimeString = `${hours}:${minutes}:${seconds}`;

    const kuwaitDateFormatted = `${String(kuwaitDate.getUTCDate()).padStart(
      2,
      "0"
    )}/${String(kuwaitDate.getUTCMonth() + 1).padStart(
      2,
      "0"
    )}/${kuwaitDate.getUTCFullYear()}`;
    return `${kuwaitDateFormatted} ${kuwaitTimeString}`;
  }

  onClickStatusButton(element: any, event: Event) {
    event.stopPropagation();
    const issuePolicyObject = this.createIssuePolicyObject(element);
    const transactionDetails = this.createTransactionDetailObject(element);
    const utmParameters = this.createUtmParameters(element);
    const entityId = +this.companyCode;
    const amountPaid = element.amountPaid;
    if (element.status === "PAID") {
      this.issuePolicy(
        issuePolicyObject,
        transactionDetails,
        amountPaid,
        entityId,
        utmParameters
      );
    } else if (element.status === "GTS_ISSUED") {
      const gtsPolicyId = element.gtsPolicyId;
      this.completeTransactionApi(
        transactionDetails,
        gtsPolicyId,
        issuePolicyObject,
        entityId
      );
    } else {
      const gtsPolicyId = element.gtsPolicyId;
      this.sendEmail(
        issuePolicyObject,
        gtsPolicyId,
        entityId,
        transactionDetails.policyId
      );
    }
  }

  createIssuePolicyObject(element: any) {
    const travelStartDate = new Date(element.inceptionDate);
    const travelEndDate = new Date(element.expiryDate);
    this._gtsService
      .zainPhoneNumberValidation({ phoneNumber: element.phoneNumber })
      .subscribe((res) => {
        this.isZainPhoneNumber = res.isValid;
      });
    if (this.isZainPhoneNumber) {
      this._gtsService
        .authenticate(environment.authenticateZain)
        .subscribe((response) => {
          this._gtsService
            .getAccount(response.account, "GTS", response.token)
            .subscribe((response) => {
              this.account = response;
            });
        });
    }
    var insuranceCompanyName = this.account?.insuranceCompanyName;
    if ((this.account?.insuranceCompanies?.length ?? 0) > 0) {
      insuranceCompanyName =
        this.account?.insuranceCompanies![0].insuranceCompanyShortName;
    }
    const issuePolicyRequest: IssuePolicyRequest = {
      countryOfResidenceCode: this.currentResidenceCountry?.code ?? "",
      productCode: this.currentPlan?.code ?? "",
      periodCode: this.currentDuration?.code ?? "7days",
      range: [
        `${travelStartDate.getFullYear()} - ${
          travelStartDate.getMonth() + 1
        } - ${travelStartDate.getDate()}`,
        `${travelEndDate.getFullYear()} - ${
          travelEndDate.getMonth() + 1
        } - ${travelEndDate.getDate()}`,
      ],
      markup: 0,
      areaOfTravelCode: this.currentCoverageZone?.code ?? "",
      destinationCode: this.currentDestinationCountry?.code ?? "",
      areaOfTravelDisplayValue: this.currentCoverageZone?.displayValue ?? "",
      destinationDisplayValue:
        this.currentDestinationCountry?.displayValue ?? "",
      questionnaire: {
        kicsportactivity: element.sportsActivityIncluded,
      },
      individualCertificateList: element.travellerDetails!.map((x) => {
        return {
          id: "null",
          relationCode: x.relationCode,
          passportNumber: x.passportNumber,
          firstName: x.firstName,
          middleName: x.middleName,
          lastName: x.lastName,
          externalReference: x.externalReference,
          dateOfBirth: x.dateOfBirth,
          genderCode: x.genderCode.toLowerCase(),
          memberAge: x.memberAge,
          sportsActivity: x.sportsActivity,
          email: x.email,
          telephoneNumber: x.telephoneNumber,
          emergencyPhoneNumber: x.emergencyPhoneNumber ?? "",
          emergencyContactName: x.emergencyContactName ?? "",
        };
      }),
      eligibleAreas: "All",
      inceptionDate: `${travelStartDate.getFullYear()} - ${
        travelStartDate.getMonth() + 1
      } - ${travelStartDate.getDate()}`,
      expiryDate: `${travelEndDate.getFullYear()} - ${
        travelEndDate.getMonth() + 1
      } - ${travelEndDate.getDate()}`,
      createdBy: environment.authenticate.Username,
      country: this.account?.country ?? "kuwait",
      countryInitials: this.account?.countryInitials ?? "KW",
      currency: this.account?.currency ?? "KWD",
      insuranceCompanyCode: +this.companyCode,
      insuranceCompanyShortName: this.companyPrefix,
      accountNumber: this.account?.accountNumber ?? "2056",
      accountName: this.account?.corporateName ?? "Zain Telecom",
      accountShortName: this.companyPrefix,
      salesPersonBrokerNumber: this.account?.salesPersonBrokerNumber ?? "",
      salesPersonName: this.account?.salesPersonName ?? "",
      insuranceCompanyName: insuranceCompanyName ?? "",
      billingType: this.account?.billingTypeCrmCode ?? "",
      userEmail: environment.authenticate.Username,
      userFullName: this._helper.getAgentFullName(),
      agentCode: this._helper.getAgentCode(),
      intermediateConnections: this.account?.intermediateConnections ?? [],
      couponName: element.couponName,
      couponType: element.couponType,
      flatPercentageValue: element.flatPercentageValue ?? null,
      discountAmountValue: element.discountAmountValue ?? "",
      rewardCreditValue: element.rewardCreditValue ?? "",
    };
    const event = new Identify();
    event.set("email", element.email);
    event.set("phone", element.phoneNumber);
    identify(event);

    amplitude.setUserId(element.email);
    return issuePolicyRequest;
  }

  createTransactionDetailObject(element: any) {
    const transactionDetails = {
      policyId: element.policyId,
      redeemPoints: 0,
      totalPremium: element.totalPremium,
      promotionName: element.promotionName,
      discountAmount: element.discountAmount ?? 0,
      basePremium: element.basePremium,
      policyFee: element.policyFee,
      issueFee: element.issueFee,
      convienceFee: element.convienceFee,
    };
    return transactionDetails;
  }

  createUtmParameters(element: any) {
    const utmParameters = {
      clickId: element.clickId ?? "",
      utmCampaign: element.utmCampaign ?? "",
      utmContent: element.utmContent ?? "",
      utmTerm: element.utmTerm ?? "",
      utmMedium: element.utmMedium ?? "",
      utmSource: element.source ?? "",
    };
    return utmParameters;
  }
  async issuePolicy(
    issuePolicyObject: IssuePolicyRequest,
    transactionDetails: TransactionDetails,
    amountPaid: number,
    entityId: number,
    utmParameters: UTMParameters
  ) {
    if (issuePolicyObject) {
      if (environment.production) {
        const policyId = transactionDetails?.policyId;
        const script = document.createElement("script");
        script.src = "assets/scripts/analytics.js";
        script.async = true;
        script.onload = () => {
          if (policyId) {
            this.trackPurchaseEvent(policyId, amountPaid, utmParameters);
          }
        };
        document.body.appendChild(script);
      }

      this._gtsService
        .issuePolicy(
          issuePolicyObject,
          transactionDetails?.policyId ?? "",
          "GTS"
        )
        .subscribe({
          next: async (response) => {
            const gtsPolicyId = response.id;
            this._gtsService.getPolicyDetails(response.id, "GTS").subscribe({
              next: async (policyDetail) => {
                const policyNumber = policyDetail.request.policyNumber;

                if (transactionDetails?.policyId) {
                  const updatePolicyReq = {
                    policyStatus: "GTS_ISSUED",
                    gtsPolicyId: response.id,
                    gtsPolicyNumber: policyNumber,
                  };
                  try {
                    await this.updatePolicyStatus(
                      transactionDetails.policyId,
                      updatePolicyReq
                    );
                  } catch (error) {
                    return;
                  }
                }
                amplitude.track("GTS_ISSUED", {
                  payload: JSON.stringify(response),
                });

                amplitude.track("Transaction Details", {
                  transactionDetails: JSON.stringify(transactionDetails),
                });

                this.completeTransactionApi(
                  transactionDetails,
                  gtsPolicyId,
                  issuePolicyObject,
                  entityId
                );
              },
            });
          },
          complete: () => {},
          error: (err) => {
            amplitude.track("Policy Issued Failed!", {
              payload: JSON.stringify({ err, issuePolicyObject }),
            });
          },
        });
    } 
  }

  completeTransactionApi(
    transactionDetails: TransactionDetails,
    gtsPolicyId: string,
    issuePolicyObject: IssuePolicyRequest,
    entityId: number
  ) {
    const policyId = transactionDetails.policyId;
    const redeemPoints = transactionDetails.redeemPoints;

    const paymentDetail = {
      policyId,
      gtsBearerToken: this._helper.getGtsBearerToken(),
      gtsEntityId: entityId,
      redeemPoints,
      status: "success",
    };
    const { gtsBearerToken, ...filteredPaymentDetail } = paymentDetail;
    amplitude.track("Calling Complete Transaction", {
      paymentDetails: JSON.stringify(filteredPaymentDetail),
    });

    this._gtsService.completeTransaction(paymentDetail, "GTS").subscribe({
      complete: async () => {
        if (transactionDetails?.policyId) {
          try {
            await this.updatePolicyStatus(transactionDetails.policyId, {
              policyStatus: "EMAIL_PENDING",
            });
            amplitude.track("EMAIL_PENDING", {
              data: JSON.stringify(transactionDetails.policyId),
            });
          } catch (error) {
            return;
          }
        }
        amplitude.track("Complete Transaction Succeeded!", {
          payload: JSON.stringify(paymentDetail),
        });

        this.sendEmail(
          issuePolicyObject,
          gtsPolicyId,
          entityId,
          transactionDetails.policyId
        );
      },
      error: (err) => {
        amplitude.track("Complete Transaction Failed!", {
          payload: JSON.stringify({ err, paymentDetail }),
        });
      },
    });
  }

  trackPurchaseEvent(
    policyId: string,
    amountPaid: number,
    utmParameters: UTMParameters
  ) {
    const clickid = utmParameters?.clickId;
    const utm_source = utmParameters?.utmSource;
    const utm_campaign = utmParameters?.utmCampaign;
    const utm_content = utmParameters?.utmContent;
    const utm_term = utmParameters?.utmTerm;
    const utm_medium = utmParameters?.utmMedium;
    this._gtmService.pushTag({
      event: "purchase",
      clickid,
      utm_source,
      utm_campaign,
      utm_content,
      utm_term,
      utm_medium,
      pageName: window.location.href,
      currency: "KWD",
      value: amountPaid,
      items: [
        {
          item_id: policyId,
          item_name: "Safratti Policy",
        },
      ],
    });

    if (utmParameters && utmParameters.utmTerm === "mobibox") {
      this._utmService.registerConversion(utmParameters).subscribe();
    }

    const purchaseEventDetail = {
      currency: "KWD",
      value: amountPaid,
    };
    // meta purchase event track
    if (typeof window !== "undefined" && typeof window.fbq === "function") {
      window.fbq("track", "Purchase", purchaseEventDetail);
      window.fbq(
        "trackSingleCustom",
        "Purchase",
        purchaseEventDetail,
        "482608628049457"
      );
    }

    // For Snapchat
    if (typeof window !== "undefined" && typeof window.snaptr === "function") {
      window.snaptr("track", "PURCHASE", purchaseEventDetail);
    }

    // For TikTok
    if (typeof window !== "undefined" && typeof window.ttq === "function") {
      window.ttq("track", "CompletePayment", purchaseEventDetail);
    }
  }

  sendEmail(
    issuePolicyObject: IssuePolicyRequest,
    gtsPolicyId: string,
    entityId: number,
    policyId: string
  ) {
    let isEmail = false;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emergencyPhoneNumber =
      issuePolicyObject.individualCertificateList[0].emergencyPhoneNumber ?? "";
    if (emailRegex.test(emergencyPhoneNumber)) {
      isEmail = true;
    }

    const emailRequest: SendEmailRequest = {
      to: [issuePolicyObject.individualCertificateList[0].email],
      cc: [isEmail ? emergencyPhoneNumber : ""],
      message: `Dear ${issuePolicyObject.individualCertificateList[0].firstName} ${issuePolicyObject.individualCertificateList[0].lastName},\n\nThank you for purchasing a policy from Safratti!\n\nYour Travel policy has been successfully issued by Kuwait Insurance Company.  With this email, we are sending you the following 3 documents for your convenience and records.\n\n1. Your Policy Certificate,\n2. Your Policy coverage terms and conditions,\n3. Your Travel Assistance Access Card\n\nDo note that these documents will not be dispatched to you in physical form, and can be printed out at your end if required.\n\nIn case you need any further information or assistance, we are available on\nPhone: +965 22207335\nWhatsApp: +965 99137722\nEmail: info@safratti.com\n\nDo keep this email safe and download a copy of the Travel Assistance card on your phone also for quick and easy access in case of any travel emergency.\n\nWe wish you a happy and safe trip!\n\nRegards,\nTeam Safratti\n\n\n`,
      policyId: gtsPolicyId,
      entityId: +entityId,
      from: "safratti@globetravelsolution.com",
      subject: "Your Safratti Travel Policy is issued",
      attachments: {
        cards: true,
        certificate: true,
        summary: true,
        terms: true,
        voucher: false,
      },
    };
    this._gtsService.sendEmailForGTS(emailRequest, "GTS").subscribe({
      next: async () => {
        amplitude.track("Email Sent Successfully.", { emailRequest });
        if (policyId) {
          try {
            await this.updatePolicyStatus(policyId, { policyStatus: "ISSUED" });
            amplitude.track("ISSUED", {
              data: policyId,
            });
            window.location.reload();
          } catch (error) {
            return;
          }
        }
      },
      error: (err) => {
        amplitude.track("Email not Sent", {
          payload: JSON.stringify({ err, emailRequest }),
        });
      },
    });
  }

  async updatePolicyStatus(
    policyId: string,
    updatePolicyReq: UpdatePolicyRequest
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this._gtsService.updatePolicyStatus(policyId, updatePolicyReq).subscribe({
        next: () => {
          this.cdr.detectChanges();
          resolve();
        },
        error: (err) => {
          amplitude.track("Update Policy Status Failed!", {
            policyId,
            status,
            error: err.toString(),
          });
          reject(
            new Error(
              `Failed to update policy status to ${status} for policyId ${policyId}`
            )
          );
        },
      });
    });
  }
}
